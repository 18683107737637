import classNames from 'classnames';
import React, { useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

interface QuestionCircleProps {
  text: string;
  relative?: boolean;
  position?: string;
}
// WARNING this thing doesnt work completely because it shouldnt take up full width but it works in the place where it is used
export default function QuestionCircle({
  text,
  relative = true,
  position = ''
}: QuestionCircleProps): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div className={classNames('w-full justify-start inline-block transition-all', { relative })}>
      <FaQuestionCircle onMouseEnter={handleHover} onMouseLeave={handleHover} className="w-3 h-3" />

      <div
        className={`${
          isHovered ? 'opacity-100' : 'opacity-0 invisible'
        } bg-black w-40 text-white transition-all z-50 text-xs min-w-24 absolute p-2 rounded flex duration-300 ${ position || 'top-5 -left-1'}`}>
        {text}
      </div>
    </div>
  );
}
