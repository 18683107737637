import _ from 'lodash';
import { CustomField, CustomFieldType, CustomLabelField } from '../types/invites';
import { ProfileDataType, ProfileField } from '../types/profile';

export function filterCustomLabelFields(fields: CustomField[]): CustomLabelField[] {
  if (!fields) return [];
  return fields.filter((field) =>
    [CustomFieldType.MULTIPLE_CHOICE, CustomFieldType.TEXT].includes(field.type),
  ) as CustomLabelField[];
}

export function filterUniqueProfileFields(
  filter: ProfileField[],
  fields: ProfileField[],
): ProfileField[] {
  return fields.filter(
    (f) =>
      !filter.some((f2) => {
        return (
          (f.dataType === ProfileDataType.EMAIL && f2.email === f.email) ||
          (f.dataType === ProfileDataType.PHONENUMBER &&
            f.phoneNumber?.prefix === f2.phoneNumber?.prefix &&
            f.phoneNumber?.suffix === f2.phoneNumber?.suffix) ||
          (f.dataType === ProfileDataType.ADDRESS && _.isEqual(f.address, f2.address)) ||
          (f.dataType === ProfileDataType.BIRTHDATE && f2.dataType === ProfileDataType.BIRTHDATE) ||
          (f.dataType === ProfileDataType.BUSINESSNAME &&
            f2.dataType === ProfileDataType.BUSINESSNAME)
        );
      }),
  );
}

export function filterPresentFields(
  profileFields: ProfileField[],
  inviteFields: ProfileField[],
): ProfileField[] {
  return _.difference(
    profileFields,
    filterUniqueProfileFields(
      _.difference(inviteFields, filterUniqueProfileFields(profileFields, inviteFields)),
      profileFields,
    ),
  );
}
