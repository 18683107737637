import React from 'react';
import {
  FaBuilding,
  FaFacebookSquare,
  FaGlassCheers,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
} from 'react-icons/fa';
import { FaCakeCandles, FaXTwitter } from 'react-icons/fa6';
import { HiOutlineCalendar } from 'react-icons/hi';
import { FiPhone, FiMapPin, FiLink } from 'react-icons/fi';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { MomentType, ProfileDataType, SocialType } from '../types/profile';

export const profileDataTypeIcons = {
  [ProfileDataType.ADDRESS]: (
    <FiMapPin className="h-5 w-5 icon " aria-hidden="true" strokeWidth="2" />
  ),
  [ProfileDataType.BIRTHDATE]: <FaCakeCandles className="h-5 w-5 icon" aria-hidden="true" />,
  [ProfileDataType.EMAIL]: (
    <HiOutlineEnvelope className="h-5 w-5  icon" strokeWidth="2" aria-hidden="true" />
  ),
  [ProfileDataType.PHONENUMBER]: <FiPhone className="h-5 w-5" strokeWidth="2" aria-hidden="true" />,
  [ProfileDataType.BUSINESSNAME]: (
    <FaBuilding className="h-5 w-5" strokeWidth="2" aria-hidden="true" />
  ),
  [ProfileDataType.MOMENT]: (
    <HiOutlineCalendar className="h-5 w-5 icon" strokeWidth="2" aria-hidden="true" />
  ),

  [ProfileDataType.SOCIAL]: <FiLink className="h-5 w-5 icon" strokeWidth="2" aria-hidden="true" />,
};

export const momentTypeIcons = {
  [MomentType.CHILDBIRTH]: <FaCakeCandles className="h-5 w-5 icon" aria-hidden="true" />,
  [MomentType.WEDDING]: <FaGlassCheers className="h-5 w-5 icon" aria-hidden="true" />,
};

export const socialTypeIcons = {
  [SocialType.FACEBOOK]: <FaFacebookSquare className="h-5 w-5   icon" aria-hidden="true" />,
  [SocialType.LINKEDIN]: <FaLinkedin className="h-5 w-5 icon" aria-hidden="true" />,
  [SocialType.X]: <FaXTwitter className="h-5 w-5 icon" aria-hidden="true" />,
  [SocialType.TIKTOK]: <FaTiktok className="h-5 w-5 icon" aria-hidden="true" />,
  [SocialType.INSTAGRAM]: <FaInstagram className="h-5 w-5 icon" aria-hidden="true" />,
};
