import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../../types/profile';
import { isValidEmail } from '../../../utils/validationUtils';
import { profileService } from '../../../services';
import useDelayedEffect from '../../../hooks/useDelayedEffect';

interface EmailInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  emailCheck?: boolean;
  setAccountExists?: (exists: boolean) => void;
  mandatory?: boolean;
  validate?: boolean;
}

export default function EmailInput({
  field,
  setField,
  canBeEmpty = false,
  emailCheck = false,
  mandatory = false,
  validate = false,
  setAccountExists = () => {},
}: EmailInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  useDelayedEffect(() => {
    if (!validate) return;
    if (!field.email) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    setError(isValidEmail(field.email) ? '' : t('invalid.invalid'));
  }, [field.email, validate]);

  const onBlur = (): void => {
    if (!emailCheck || !field.email || !isValidEmail(field.email)) return;
    profileService
      .checkEmailExists(field.email)
      .then((exists): void => {
        setAccountExists(exists);
        setError('');
      })
      .catch(() => setError(t('invalid.invalid')));
  };

  return (
    <div className="relative flex w-full flex-col">
      <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
        {t('form.email.email')} {mandatory ? '*' : ''}
      </label>
      <input
        id={field.id}
        onBlur={onBlur}
        placeholder={t('form.email.placeholder')}
        className={classNames('h-10 w-full rounded-lg border px-2 text-gray-600 focus:ring-0 focus:ring-transparent', {
          '!border-error': error,
        })}
        name="email"
        data-testid={`email-input-${field.id}`}
        type="text"
        value={field.email ?? ''}
        onChange={(e) => setField({ ...field, email: e.target.value })}
      />
      <p className="text-error absolute right-1 top-0 text-sm transition-all">{error}</p>
    </div>
  );
}
