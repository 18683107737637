/* eslint-disable default-case */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useEffectAfterFirstRender from '../../../hooks/useEffectAfterFirstRender';
import { Address, AddressGeneral, ProfileDataType, ProfileField } from '../../../types/profile';
import { createEmptyField } from '../../../utils/formatUtils';
import httpService from '../../../services/httpService';
import useDelayedEffect from '../../../hooks/useDelayedEffect';
import { IsValidField, isEmptyField } from '../../../utils/validationUtils';
import {
  COLOR_ERROR,
  COLOR_PRIMARY,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_200,
  COLOR_SECONDARY_50,
} from '../../../constants';

interface AddressInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  validate?: boolean;
}

type SelectOption = {
  label: string;
  value: string;
} | null;

interface AddressFetchParams {
  postCode: string;
  houseNumber: string;
  countryCode: string;
  houseNumberAddition?: string;
}

export default function AddressInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  validate = false,
}: AddressInputProps): JSX.Element {
  const [value, setValue] = useState<SelectOption>();
  const [address, setAddress] = useState<AddressGeneral>();
  const [addressString, setAddressString] = useState<string>('');
  const [error, setError] = useState<string>('');
  const options = useMemo(() => countryList().getData(), []);

  useDelayedEffect(() => {
    if (!validate) return;
    if (isEmptyField(field)) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    if (!IsValidField(field)) {
      setError(t('invalid.invalid'));
      return;
    }

    setError('');
  }, [field.address, validate]);

  const { t } = useTranslation();

  const changeHandler = (v: SelectOption) => {
    setValue(v);
  };

  const fetchAddress = async () => {
    if ((value?.value! === 'NL' || !value?.value) && address?.postCode && address?.houseNumber) {
      let params: AddressFetchParams = {
        postCode: address.postCode,
        houseNumber: address.houseNumber,
        countryCode: 'NL',
      };
      if (address.houseNumberAddition) params = { ...params, houseNumberAddition: address.houseNumberAddition };

      const { data } = await httpService.get('/address', { params }).catch(() => {
        setAddressString('');
        return { data: undefined };
      });

      if (!data) return;
      setField({
        ...field,
        address: data.address,
      });
      setAddressString(data.display);
    }
  };

  useDelayedEffect(fetchAddress, [address, value]);

  const addressEmpty = () => {
    if (!address) return true;
    return field.address?.countryCode === 'NL'
      ? address?.postCode === '' && address?.houseNumber === ''
      : address?.city === '' &&
          address?.street === '' &&
          address?.houseNumber === '' &&
          address?.houseNumberAddition === '' &&
          address?.postCode === '';
  };

  useEffect(() => {
    if (!addressEmpty()) {
      setField({
        ...field,
        address: {
          ...address,
          countryCode: value?.value || field?.address?.countryCode,
          country: value?.label || field?.address?.country,
        } as Address,
      });
      setAddressString('');
    }
  }, [address]);

  useEffectAfterFirstRender(() => {
    if (addressEmpty()) {
      const addressField = field.address;
      setValue(
        addressField?.country && addressField?.countryCode
          ? {
              label: addressField.country,
              value: addressField.countryCode,
            }
          : value,
      );

      setField({ ...field, address: createEmptyField(ProfileDataType.ADDRESS).address });
    }
  }, [address]);

  const runRef = useRef<boolean>(false); //needed to know whether form has been typed in before
  useEffect(() => {
    // needed for setting the value if passed a field with a country code
    if (!value && field.address?.countryCode) {
      setValue({
        label: countryList().getLabel(field.address.countryCode),
        value: field.address.countryCode,
      });
    }

    // needed for defaulting to NL on first render but not subsequent ones
    if (runRef.current) return;
    if (isEmptyField(field)) {
      setValue({ label: 'Netherlands', value: 'NL' });
    } else runRef.current = true;
  }, [field]);

  useEffect(() => {
    if (!value?.label || !value?.value) return;
    if (field.address?.country !== value?.label && field.address?.countryCode !== value?.value && !isEmptyField(field))
      setField({
        ...field, // @ts-ignore
        address: { ...field.address!, country: value?.label, countryCode: value?.value },
      });
  }, [value]);

  const renderInput = () => {
    const addressGeneral = field.address as AddressGeneral;
    switch (value?.value || addressGeneral.countryCode) {
      case 'NL':
        return (
          <div className="flex w-full flex-col">
            <div className="mt-2 flex w-full gap-2 flex-row">
              <div className="flex w-4/6 flex-col">
                <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
                  {t('form.address.postalCode')} {mandatory ? '*' : ''}
                </label>
                <input
                  id={field.id}
                  type="text"
                  name="postal-code"
                  placeholder={t('form.address.placeholder.postalCode')}
                  value={addressGeneral.postCode ?? ''}
                  onChange={(e) =>
                    setAddress({
                      ...addressGeneral!,
                      postCode: e.target.value.replaceAll(' ', '').toUpperCase(),
                    })
                  }
                  data-testid={`street-input-${field.id}`}
                  className={classNames(
                    'mr-1 h-10 w-full rounded-lg border border-gray-400 px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
                    {
                      '!border-error': error,
                    },
                  )}
                />
              </div>
              <div className="flex w-1/6 flex-col">
                <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
                  {t('form.address.houseNumber')} {mandatory ? '*' : ''}
                </label>
                <input
                  id={field.id}
                  type="text"
                  placeholder={t('form.address.placeholder.houseNumber')}
                  value={addressGeneral.houseNumber ?? ''}
                  onChange={(e) => setAddress({ ...addressGeneral!, houseNumber: e.target.value })}
                  data-testid={`number-input-${field.id}`}
                  className={classNames(
                    'mr-1 h-10 w-full rounded-lg border border-gray-400 px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
                    {
                      '!border-error': error,
                    },
                  )}
                />
              </div>
              <div className="flex w-1/6 flex-col">
                <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
                  {t('form.address.houseNumberAddition')}
                </label>
                <input
                  id={field.id}
                  type="text"
                  value={addressGeneral.houseNumberAddition ?? ''}
                  onChange={(e) => setAddress({ ...addressGeneral!, houseNumberAddition: e.target.value })}
                  data-testid={`houseNumberAddition-input-${field.id}`}
                  className={classNames(
                    'focus:border-3 mr-1 h-10 w-full rounded-lg border border-gray-400 px-2 text-gray-600 focus:border-[#01AFA5] focus:ring-0 focus:ring-transparent',
                    {
                      '!border-error': error,
                    },
                  )}
                />
              </div>
            </div>
            <p className={`mx-auto mt-2 text-sm ${!addressString && 'hidden'}`}>{addressString}</p>
          </div>
        );
      default:
        return (
          <>
            {' '}
            <div className="my-1 flex w-full flex-row gap-2">
              <div className="flex w-4/6 flex-col">
                <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
                  {t('form.address.street')} {mandatory ? '*' : ''}
                </label>
                <input
                  id={field.id}
                  type="text"
                  name="street-address"
                  placeholder={t('form.address.placeholder.street')}
                  data-testid={`street-input-${field.id}`}
                  value={addressGeneral.street}
                  className={classNames(
                    'focus:border-3 mr-1 h-10 w-full rounded-lg border border-gray-400 px-2 text-gray-600 focus:border-[#01AFA5] focus:ring-0 focus:ring-transparent',
                    {
                      '!border-error': error,
                    },
                  )}
                  onChange={(e) => setAddress({ ...addressGeneral!, street: e.target.value })}
                />
              </div>
              <div className="flex w-1/6 flex-col">
                <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
                  {t('form.address.houseNumber')} {mandatory ? '*' : ''}
                </label>
                <input
                  id={field.id}
                  name="street-address"
                  type="text"
                  placeholder={t('form.address.placeholder.houseNumber')}
                  data-testid={`number-input-${field.id}`}
                  value={addressGeneral.houseNumber}
                  className={classNames(
                    'focus:border-3 mr-1 h-10 w-full rounded-lg border border-gray-400 px-2 text-gray-600 focus:border-[#01AFA5] focus:ring-0 focus:ring-transparent',
                    {
                      '!border-error': error,
                    },
                  )}
                  onChange={(e) => setAddress({ ...addressGeneral!, houseNumber: e.target.value })}
                />
              </div>
              <div className="flex w-1/6 flex-col">
                <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
                  {t('form.address.houseNumberAddition')}
                </label>
                <input
                  id={field.id}
                  type="text"
                  data-testid={`houseNumberAddition-input-${field.id}`}
                  value={addressGeneral.houseNumberAddition}
                  className={classNames(
                    'focus:border-3 mr-1 h-10 w-full rounded-lg border border-gray-400 px-2 text-gray-600 focus:border-[#01AFA5] focus:ring-0 focus:ring-transparent',
                    {
                      '!border-error': error,
                    },
                  )}
                  onChange={(e) => setAddress({ ...addressGeneral!, houseNumberAddition: e.target.value })}
                />
              </div>
            </div>
            <div className="mt-1 flex flex-row gap-2">
              <div className="flex w-1/3 flex-col">
                <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
                  {t('form.address.postalCode')} {mandatory ? '*' : ''}
                </label>
                <input
                  id={field.id}
                  name="street-address"
                  type="text"
                  placeholder={t('form.address.placeholder.postalCode')}
                  data-testid={`postCode-input-${field.id}`}
                  value={addressGeneral.postCode}
                  className={classNames(
                    'focus:border-3 mr-1 h-10 w-full rounded-lg border border-gray-400 px-2 text-gray-600 focus:border-[#01AFA5] focus:ring-0 focus:ring-transparent',

                    {
                      '!border-error': error,
                    },
                  )}
                  onChange={(e) =>
                    setAddress({
                      ...addressGeneral!,
                      postCode: e.target.value.replaceAll(' ', '').toUpperCase(),
                    })
                  }
                />
              </div>
              <div className="flex w-2/3 flex-col">
                <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
                  {t('form.address.city')} {mandatory ? '*' : ''}
                </label>
                <input
                  id={field.id}
                  name="street-address"
                  type="text"
                  placeholder={t('form.address.placeholder.city')}
                  data-testid={`city-input-${field.id}`}
                  value={addressGeneral.city}
                  className={classNames(
                    'focus:border-3 mr-1 h-10 w-full rounded-lg border border-gray-400 px-2 text-gray-600 focus:border-[#01AFA5] focus:ring-0 focus:ring-transparent',
                    {
                      '!border-error': error,
                    },
                  )}
                  onChange={(e) => setAddress({ ...addressGeneral!, city: e.target.value })}
                />
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="relative flex w-full flex-col">
      <div className="flex w-full flex-col">
        <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
          {t('form.address.country')} {mandatory ? '*' : ''}
        </label>
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: !error ? (state.isFocused ? COLOR_PRIMARY_300 : `${COLOR_SECONDARY_200}80`) : COLOR_ERROR,
              borderRadius: '15px',
              borderWidth: '2px',
              color: COLOR_PRIMARY_900,
              backgroundColor: `${COLOR_SECONDARY_200}80`,
              boxShadow: 'none',
              height: '50px',
              fontWeight: '500',
              fontSize: '16px',
              '&:hover': {
                borderColor: !error ? (state.isFocused ? COLOR_PRIMARY_300 : `${COLOR_SECONDARY_200}80`) : COLOR_ERROR,
              },
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              height: 'hug',
            }),
            option: (styles, { isSelected }) => {
              return {
                ...styles,
                backgroundColor: isSelected ? COLOR_PRIMARY : COLOR_SECONDARY_50,
                color: isSelected ? COLOR_SECONDARY_50 : COLOR_PRIMARY_900,
                '&:hover': {
                  backgroundColor: isSelected ? COLOR_PRIMARY_300 : COLOR_PRIMARY_300,
                  color: COLOR_PRIMARY_900,
                },
              };
            },
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: (baseStyles, state) => ({
              ...baseStyles,
              color: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
            }),
          }}
          options={options as any}
          placeholder={t('form.address.placeholder.country')}
          value={{
            label: value?.label || field.address?.country || 'Netherlands',
            value: value?.value || field.address?.countryCode || 'NL',
          }}
          onChange={changeHandler}
          className="mb-1"
        />
      </div>
      {renderInput()}
      <p className="text-error absolute right-1 top-0 text-sm transition-all">{error}</p>
    </div>
  );
}
