import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomField, CustomFieldResponses, CustomFieldType } from '../../types/invites';

interface CustomFieldsFormProps {
  fields: CustomField[];
  response: CustomFieldResponses;
  setResponse: (response: CustomFieldResponses) => void;
}

export default function CustomFieldsForm({ fields, response, setResponse }: CustomFieldsFormProps): JSX.Element {
  const { t } = useTranslation();

  const renderField = (field: CustomField, index: number): JSX.Element => {
    switch (field.type) {
      case CustomFieldType.TEXT:
        return (
          <div key={index} className="w-full overflow-visible">
            <p className="pb-1 pr-2 font-medium">{`${field.label} ${field.mandatory ? '*' : ''}`}</p>
            <input
              type="text"
              placeholder={t('form.customFields.placeholder')}
              className="h-[50px] w-full px-2 focus:ring-0"
              value={response[field.customFieldId]}
              onChange={(e): void => {
                const newResponse = { ...response };
                newResponse[field.customFieldId] = e.target.value;
                setResponse(newResponse);
              }}
            />
          </div>
        );
      case CustomFieldType.MULTIPLE_CHOICE:
        const onChange = (option: string): void => {
          const newResponse = { ...response };
          if (field.mandatory && response[field.customFieldId] === option) {
            return;
          }
          newResponse[field.customFieldId] = response[field.customFieldId] !== option ? option : '';
          setResponse(newResponse);
        };
        return (
          <div key={index} className="w-full overflow-visible">
            <p className="pr-2 font-medium">{`${field.label} ${field.mandatory ? '*' : ''}`}</p>
            {field.options!.map(
              (option, j): JSX.Element => (
                <div key={j} className="flex cursor-pointer items-center gap-2" onClick={() => onChange(option)}>
                  <input
                    className="form-checkbox size-5 cursor-pointer rounded-full border-primary bg-secondary-50 text-primary-300 accent-primary-900 focus:ring-0 focus:ring-transparent"
                    type="checkbox"
                    checked={response[field.customFieldId] === option}
                    onChange={() => onChange(option)}
                  />
                  <p className="py-2 text-sm">{option}</p>
                </div>
              ),
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {/* {filterCustomLabelFields(fields).length > 0 && label && (
        <label className="mb-1 pr-2 text-base font-medium">{t('form.customFields.label')}</label>
      )} */}
      <div className="flex flex-col gap-3">{fields.map(renderField)}</div>
    </div>
  );
}
