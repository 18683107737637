import React, { useEffect } from 'react';
import { ProfileField, SocialType } from '../../../types/profile';
import DropdownSelect from './DropdownSelect';
import SocialInput from './SocialInput';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';

interface SocialChoiceInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  mandatory?: boolean;
  label?: boolean;
  excludeSocials: SocialType[];
}

export default function SocialChoiceInput({
  field,
  setField,
  mandatory = false,
  label = false,
  excludeSocials,
}: SocialChoiceInputProps): JSX.Element {
  const options = Object.values(SocialType).filter(
    (x) => !excludeSocials.includes(x) || x === field.social?.socialType,
  );

  useEffect(() => {
    if (!options.length || field.social?.socialType) return;
    setField({ ...field, social: { link: field.social?.link ?? '', socialType: options[0] } });
  }, []);

  if (!field.social) return <></>;
  return (
    <div className="flex w-full flex-col gap-2">
      {options.length > 1 && (
        <div className="flex w-full flex-col">
          <label
            htmlFor={field.id}
            className="focus:border-3 mb-1 pr-2 font-medium">
            Type
          </label>
          <DropdownSelect
            options={options.map((v) => ({ value: v, label: capitalizeFirstLetter(v) }))}
            setValue={(v) => v && setField({ ...field, social: { ...(field.social! ?? {}), socialType: v } })}
            value={field.social.socialType}
          />
        </div>
      )}
      <SocialInput field={field} setField={setField} mandatory={mandatory} />
    </div>
  );
}
