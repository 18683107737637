import React from 'react';
import { useTranslation } from 'react-i18next';
import { InviteType } from '../../types/invites';

interface InvalidAuthHeaderProps {
  type: InviteType;
}
export default function InvalidAuthHeader({ type }: InvalidAuthHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const typeToString = (): string => {
    switch (type) {
      case InviteType.COMMUNITY_CONTACT_INVITE:
        return t('header.invalidAuth.communityContactInvite');
      case InviteType.COMMUNITY_REQUEST:
        return t('header.invalidAuth.communityRequest');
      case InviteType.DATA_REQUEST:
        return t('header.invalidAuth.dataRequest');
      case InviteType.INTRODUCTION:
        return t('header.invalidAuth.introduction');
      case InviteType.EMAIL_PREFERENCE:
        return t('header.invalidAuth.emailPreference');
      case InviteType.CONNECTION:
        return t('header.invalidAuth.connectionInvite');
      default:
        return '';
    }
  };
  return (
    <>
      <h1 className="text-2xl font-serif font-medium">{typeToString()}</h1>
      {/* <p className="text-sm">{t('header.invalidAuth.app')}</p> */}
      <div className="flex">
        <img
          src="/images/misc/shield.svg"
          alt="Onboarding visual"
          className="object-cover w-5 h-5 mr-2 mt-[3px]"
        />
        <p className=" font-light text-sm w-fit h-fit">{t('header.invalidAuth.message')}</p>
      </div>
    </>
  );
}
