import httpService from './httpService';
import { Event, EventParticipationAnswer } from '../types/Events';

export async function getEvent(id: string): Promise<Event> {
  const response = await httpService.get(`/events/${id}`);
  return response.data;
}

export async function respondToEvent(
  eventId: string,
  answer: EventParticipationAnswer,
  description = '',
): Promise<void> {
  const response = await httpService.post(`/events/${eventId}/respond`, { answer, description });
  return response.data;
}

export async function respondToEventPollBulk(
  eventId: string,
  responses: { [customFieldId: string]: string },
): Promise<boolean> {
  const response = await httpService.post(`/events/${eventId}/poll/respond/bulk`, { responses });
  return response.data;
}

export async function respondToEventDateOptionBulk(
  eventId: string,
  dateOptions: { dateOptionId: number; answer: EventParticipationAnswer; description?: string }[],
): Promise<boolean> {
  const response = await httpService.post(`/events/${eventId}/date-options/respond/bulk`, { eventId, dateOptions });
  return response.data;
}

export async function getEventInviteWithToken(token: string): Promise<Event> {
  const response = await httpService.get(`/events/invite/${token}`);
  return response.data;
}
