import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SuccessPageState } from '../../types/navigation';
import CommonPageWrapper from '../../components/layouts/CommonPageWrapper';
import { profileService } from '../../services';
import { UserStats } from '../../types/profile';
import UserStatsDisplay from '../../components/misc/UserStatsDisplay';
import useAuth from '../../hooks/useAuth';
import ConnectionOverviewScreen from '../../components/overviews/ConnectionOverviewScreen';
import CommunityOverviewScreen from '../../components/overviews/CommunityOverviewScreen';
import ContactsOverviewScreen from '../../components/overviews/ContactsOverviewScreen';
import BusinessOverviewScreen from '../../components/overviews/BusinessOverviewScreen';
import useEffectAfterFirstRender from '../../hooks/useEffectAfterFirstRender';
import Button from '../../components/misc/Button';

export default function SuccessPage(): JSX.Element {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { requesterId, requesterAlias, communityInvite, businessInvite, translationKey, translationOptions } =
    (location.state as SuccessPageState) ?? {};

  const [stats, setStats] = React.useState<UserStats>();

  useEffect(() => {
    if (!location.state) navigate('/');
  }, []);

  useEffect(() => {
    if (!auth) return;

    profileService.getUserStatistics().then((data) => {
      setStats(data);
    });
  }, [JSON.stringify(auth)]);

  useEffectAfterFirstRender(() => {
    if (!auth) {
      navigate('/');
      toast.error(t('toast.error.general.loggedOut'));
    }
  }, [JSON.stringify(auth)]);

  return (
    <CommonPageWrapper>
      <CommonPageWrapper.Header>
        <div className="relative flex h-full w-full items-center justify-center gap-2.5 rounded-[40px] bg-primary-300 p-5">
          <ConnectionOverviewScreen requesterId={requesterId} requesterAlias={requesterAlias} />
          <CommunityOverviewScreen invite={communityInvite} />
          <BusinessOverviewScreen invite={businessInvite} />
          {!requesterId && !communityInvite && !businessInvite && <ContactsOverviewScreen />}
        </div>
      </CommonPageWrapper.Header>
      <CommonPageWrapper.Title>
        {t('page.common.success.title', { name: auth?.alias.split('+')[0] })}
      </CommonPageWrapper.Title>
      <CommonPageWrapper.Message>
        {translationKey ? t(translationKey, translationOptions) : ''}
      </CommonPageWrapper.Message>

      <div className="flex h-full w-full flex-1 justify-between">
        <UserStatsDisplay stats={stats} />
        <Link to="/">
          <Button variant="primary">{t('general.close')}</Button>
        </Link>
      </div>
    </CommonPageWrapper>
  );
}
