import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import nl from './nl.json';
import en from './en.json';
import { store } from '../redux/store';
import { RootState } from '../types/redux';

i18n.use(initReactI18next).init({
  fallbackLng: 'nl',
  debug: true,
  resources: {
    nl,
    en,
  },
  interpolation: { escapeValue: false },
});

store.subscribe((): void => {
  const state = store.getState() as RootState;
  i18n.changeLanguage(state.application.language);
});

export default i18n;
