import React from 'react';
import { twMerge } from 'tailwind-merge';
import { MobileOS } from '../../types/misc';
import { detectMobileOS } from '../../utils/userAgentUtils';
import useLanguage from '../../hooks/useLanguage';

interface DownloadButtonProps {
  className?: string;
}

export default function DownloadButton({ className }: DownloadButtonProps): JSX.Element {
  const mobileOS = detectMobileOS();
  const lang = useLanguage();

  return (
    <div className={twMerge('flex flex-col gap-2 lg:flex-row lg:gap-4', className)}>
      {mobileOS !== MobileOS.IOS && (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.VeraConnect.Frontend&hl=nl&pcampai[…]ampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            className="h-[56px]"
            alt="Ontdek het op Google Play"
            src={`${process.env.PUBLIC_URL}/images/ps/${lang}.svg`}
          />
        </a>
      )}
      {mobileOS !== MobileOS.ANDROID && (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://apps.apple.com/nl/app/vera-connect/id1667301680?itsct=apps_box_badge&amp;itscg=30200">
          <img className="h-[56px]" alt="App store badge" src={`${process.env.PUBLIC_URL}/images/as/${lang}.svg`} />
        </a>
      )}
    </div>
  );
}
