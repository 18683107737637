export type ProfileField = {
  id?: string;
  label: Label;
  description: string;
  dataType: ProfileDataType;
  email?: string;
  primary?: boolean;
  address?: Address;
  phoneNumber?: {
    prefix: string;
    suffix: string;
    countryCode: string;
  };
  birthDate?: string;
  moment?: {
    name: string;
    momentType: MomentType;
    date: string;
  };
  social?: SocialMedia;
};

export interface SocialMedia {
  socialType: SocialType;
  link: string;
}
export enum MomentType {
  WEDDING = 'WEDDING',
  CHILDBIRTH = 'CHILDBIRTH',
}

export enum SocialType {
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  X = 'X',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
}

export enum Label {
  GENERAL = 'GENERAL',
  PRIVATE = 'PRIVATE',
  WORK = 'WORK',
  MASKED_EMAIL = 'MASKED_EMAIL',
}

export enum ProfileDataType {
  BIRTHDATE = 'BIRTHDATE',
  EMAIL = 'EMAIL',
  PHONENUMBER = 'PHONENUMBER',
  ADDRESS = 'ADDRESS',
  MOMENT = 'MOMENT',
  BUSINESSNAME = 'BUSINESSNAME',
  SOCIAL = 'SOCIAL',
}

export const profileDataTypeOrder = [
  ProfileDataType.EMAIL,
  ProfileDataType.PHONENUMBER,
  ProfileDataType.ADDRESS,
  ProfileDataType.BIRTHDATE,
  ProfileDataType.MOMENT,
  ProfileDataType.BUSINESSNAME,
];

export type Address = AddressGeneral | AddressUS;

export type AddressGeneral = {
  street: string;
  houseNumber: string;
  houseNumberAddition: string;
  city: string;
  postCode: string;
  country: string;
  countryCode: string;
};

export interface AddressUS {
  countryCode: 'US';
  streetLine: string;
  streetLineTwo?: string;
  city: string;
  state: string;
  zipCode: string;
  country: 'United States';
}

/**
 * The body containing the user stats
 * for the user who requested it
 */
export interface UserStats {
  /**
   * Number of communities
   */
  communities: number;
  /**
   * Number of connections
   */
  connections: number;

  /**
   * Number of notifications
   */
  notifications: number;
}

export interface EmailProperty {
  email: string;
  type: PropertyType.EMAIL;
}

export interface PhoneNumberProperty {
  prefix: string;
  suffix: string;
  countryCode: string;
  type: PropertyType.PHONENUMBER;
}

export type AddressProperty = Address & { type: PropertyType.ADDRESS };

export type Property = EmailProperty | PhoneNumberProperty | AddressProperty;

export enum PropertyType {
  EMAIL = 2,
  PHONENUMBER = 3,
  ADDRESS = 4,
}
