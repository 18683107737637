import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonPageWrapper from '../../components/layouts/CommonPageWrapper';

export default function NotFoundPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <CommonPageWrapper>
      <CommonPageWrapper.Header>
        <div className="w-full relative items-center justify-center flex h-full bg-secondary rounded-[40px] p-5 gap-2.5">
          <img
            alt="Not found"
            src={`${process.env.PUBLIC_URL}/images/figures/mess_sad.svg`}
            className=" h-full w-[80%] mx-auto"
          />
        </div>
      </CommonPageWrapper.Header>
      <CommonPageWrapper.Title>{t('page.common.notFound.title')}</CommonPageWrapper.Title>
    </CommonPageWrapper>
  );
}
