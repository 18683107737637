import React from 'react';

type Props = {
  label: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder: string;
  required?: boolean;
  textarea?: boolean;
  inputStyle?: string;
  labelStyle?: string;
  resizable?: boolean;
};

function GenericInput({
  label,
  type = 'text',
  value,
  onChange,
  placeholder,
  required = false,
  textarea = false,
  inputStyle = '',
  labelStyle = '',
  resizable = true,
}: Props) {
  const InputTag = textarea ? 'textarea' : 'input';
  const textAreaStyle = 'h-24';

  if (!resizable) {
    inputStyle += ' resize-none';
  }

  return (
    <div className="flex flex-col">
      <label htmlFor={label} className={`ml-2 text-sm ${labelStyle}`}>
        {label}
        {required && '*'}
      </label>
      <InputTag
        id={label}
        name={label}
        {...(textarea && { rows: 4 })}
        {...(!textarea && { type })}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        className={`w-full rounded-xl bg-secondary-50 p-2 ${inputStyle} ${textarea ? textAreaStyle : ''}`}
      />
    </div>
  );
}

export default GenericInput;
