import { combineReducers } from 'redux';
import applicationReducer from './slices/applicationSlice';

const combinedReducer = combineReducers({
  application: applicationReducer,
});

const rootReducer = (state: any, action: any): any => {
  if (action.type === 'application/logout') {
    localStorage.removeItem('persist:root');
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
