import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import ModalWrapper from '../layouts/ModalWrapper';
import PrivacyText from '../misc/PrivacyText';

type PrivacyAndConditionsProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function PrivacyAndConditionsModal({
  open,
  setOpen,
}: PrivacyAndConditionsProps): JSX.Element {
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="bg-secondary-50 rounded-[20px] p-10 max-w-7xl h-[600px] z-50 relative py-14">
      <div className="overflow-auto h-full">
        <PrivacyText />
      </div>
      <button onClick={() => setOpen(false)} type="button" className="absolute text-primary-900 top-3 right-3">
        <AiOutlinePlus className="h-7 w-7 rotate-45" />
      </button>
    </ModalWrapper>
  );
}

