import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { FaCakeCandles } from 'react-icons/fa6';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label, ProfileDataType, ProfileField } from '../../types/profile';
import { createEmptyField } from '../../utils/formatUtils';
import { profileService } from '../../services';
import ProfileFieldForm from '../forms/profile/ProfileFieldForm';
import { capitalizeFirstLetter, profileDataTypeToString } from '../../utils/stringUtils';
import { isEmptyField, IsValidField } from '../../utils/validationUtils';
import ModalWrapper from '../layouts/ModalWrapper';
import Button from '../misc/Button';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_200,
  COLOR_SECONDARY_50,
} from '../../constants';
import { profileDataTypeIcons } from '../../utils/iconUtils';

interface ProfileModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  type: ProfileDataType;
  modalType: 'CREATE' | 'EDIT';
  field?: ProfileField;
  onSave?: (f: ProfileField) => void;
}

export default function ProfileFieldModal({
  open,
  setOpen,
  type,
  modalType,
  field,
  onSave,
}: ProfileModalProps): JSX.Element {
  const { t } = useTranslation();

  const [modalField, setModalField] = useState<ProfileField>();
  const [validate, setValidate] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!open) return;
    if (field) setModalField(field);
    else setModalField(createEmptyField(type));
    setValidate(false);
  }, [open]);

  const getHeaderItems = (): JSX.Element => {
    switch (type) {
      case ProfileDataType.EMAIL:
        return <FaEnvelope className="icon h-6 w-6 text-xl text-secondary-50" aria-hidden="true" />;
      case ProfileDataType.ADDRESS:
        // eslint-disable-next-line react/jsx-no-undef
        return <FaMapMarkerAlt className="icon h-6 w-6 text-secondary-50" aria-hidden="true" />;
      case ProfileDataType.PHONENUMBER:
        return <FaPhoneAlt className="icon h-6 w-6 text-secondary-50" aria-hidden="true" />;
      case ProfileDataType.BIRTHDATE:
        return <FaCakeCandles className="icon h-6 w-6 text-secondary-50" aria-hidden="true" />;
      default:
        return <></>;
    }
  };

  const handleSubmit = async () => {
    setValidate(true);
    if (!modalField?.label) {
      toast.error(t('toast.error.profileFieldModal.label'));
      return;
    }
    if (!IsValidField(modalField) || isEmptyField(modalField)) {
      toast.error(t('toast.error.profileFieldModal.field'));
      return;
    }
    if (modalType === 'CREATE') await profileService.addFields([modalField]).then((x) => onSave?.(x[0]));
    else if (modalType === 'EDIT') await profileService.updateField(modalField);
    setOpen(false);
  };

  const labelOptions = [
    { label: 'General', value: Label.GENERAL },
    { label: 'Work', value: Label.WORK },
    { label: 'Private', value: Label.PRIVATE },
  ];

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="h-a z-50 mx-6 w-fit min-w-[350px] max-w-[600px] rounded-3xl bg-secondary-50 px-10 py-6 pb-4 pt-5">
      <div className="flex w-full flex-col">
        <div className="mb-2 flex items-center">
          <div className="flex h-11 w-11 items-center justify-center rounded-full bg-secondary text-secondary-50">
            {profileDataTypeIcons[type]}
          </div>
          <h1 className="ml-2 text-xl font-semibold">
            {`${capitalizeFirstLetter(t('general.your'))} ${profileDataTypeToString(type)}`}
          </h1>
        </div>
        <div>
          {type !== ProfileDataType.BIRTHDATE &&
            type !== ProfileDataType.BUSINESSNAME &&
            type !== ProfileDataType.SOCIAL &&
            type !== ProfileDataType.MOMENT && (
              <>
                <label className="focus:border-3 mb-1 pr-2 font-medium">Label</label>
                <Select
                  isSearchable={false}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? COLOR_PRIMARY_300 : `${COLOR_SECONDARY_200}80`,
                      borderRadius: '15px',
                      borderWidth: '2px',
                      color: COLOR_PRIMARY_900,
                      backgroundColor: `${COLOR_SECONDARY_200}80`,
                      boxShadow: 'none',
                      height: '50px',
                      fontWeight: '500',
                      fontSize: '16px',
                      '&:hover': {
                        borderColor: state.isFocused ? COLOR_PRIMARY_300 : `${COLOR_SECONDARY_200}80`,
                      },
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      height: 'hug',
                    }),
                    option: (styles, { isSelected }) => {
                      return {
                        ...styles,
                        backgroundColor: isSelected ? COLOR_PRIMARY : COLOR_SECONDARY_50,
                        color: isSelected ? COLOR_SECONDARY_50 : COLOR_PRIMARY_900,
                        '&:hover': {
                          backgroundColor: isSelected ? COLOR_PRIMARY_300 : COLOR_PRIMARY_300,
                          color: COLOR_PRIMARY_900,
                        },
                      };
                    },
                    indicatorSeparator: () => ({
                      display: 'none',
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
                    }),
                  }}
                  options={labelOptions as any}
                  value={{
                    label: labelOptions.find((x) => x.value === modalField?.label)?.label! || 'General',
                    value: labelOptions.find((x) => x.label === modalField?.label)?.value! || 'GENERAL',
                  }}
                  onChange={(v) => setModalField({ ...modalField!, label: v?.value! })}
                />
              </>
            )}
          <div
            className={classNames('mb-4 mt-2', {
              'w-1/2': type === ProfileDataType.BIRTHDATE,
            })}>
            <ProfileFieldForm field={modalField!} validate={validate} setField={setModalField} />
          </div>
        </div>
        <div data-testid="buttons" className="flex justify-end">
          <Button className="underline" onClick={() => setOpen(false)}>
            {t('general.cancel')}
          </Button>
          <Button variant="primary" className="px-[32px]" onClick={handleSubmit}>
            {t('general.save')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
}
