import React from 'react';
import { useTranslation } from 'react-i18next';

interface InviteHeaderProps {
  requesterName: string;
  message?: string;
}
export default function DataRequestHeader({
  requesterName,
  message = '',
}: InviteHeaderProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="text-2xl font-serif font-medium">
        {message || t('header.dataRequest.title', { name: requesterName })}
      </h1>
      <div className="flex">
        <img
          src="/images/misc/shield.svg"
          alt="Onboarding visual"
          className="object-cover w-5 h-5 mr-2 mt-[3px]"
        />
        <p className=" font-light text-sm w-fit h-fit">
          {t('header.invalidAuth.message')}
        </p>
      </div>
    </>
  );
}
