import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import { authenticationService } from '../../services';
import { InviteType } from '../../types/invites';
import { InvalidAccountResponse } from '../../types/responses';
import Button from './Button';

interface InvalidAuthBodyProps {
  info?: InvalidAccountResponse;
  invitationId: string;
  inviteType: InviteType;
}

export default function InvalidAuthBody({
  info = undefined,
  invitationId,
  inviteType,
}: InvalidAuthBodyProps): JSX.Element {
  const auth = useAuth();
  const { t } = useTranslation();

  const [emailInput, setEmailInput] = React.useState(info?.email || '');

  const handleSubmit = async () => {
    await authenticationService
      .generateMagicLink(emailInput, invitationId, '', inviteType)
      .then(() => toast.success(t('toast.success.login')))
      .catch(() => {});
  };

  const getMessage = () => {
    switch (inviteType) {
      case InviteType.EMAIL_PREFERENCE:
        return t('page.invalidAuth.message.email');
      case InviteType.INTRODUCTION:
        return t('page.invalidAuth.message.introduction');
      default:
        return t('page.invalidAuth.message.general', {
          email: info?.email,
          name: info?.alias.replace('+', ' '),
        });
    }
  };

  return (
    <div className="gap-4 flex flex-col flex-1 h-full">
      <div className="flex flex-col">
        <h2 className="font-medium text-lg">
          {auth ? t('page.invalidAuth.wrongAccount') : t('page.invalidAuth.signIn')}
        </h2>
        <p className="text-sm">{getMessage()}</p>
      </div>
      {!info && (
        <div className="flex flex-col w-full">
          <label className="pr-2 mb-1 text-sm focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3">
            {t('form.email.email')}
          </label>
          <input
            className=" w-full focus:ring-0 focus:ring-transparent h-[40px]  "
            name="email"
            type="text"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />
        </div>
      )}
      <div className="flex justify-end mt-4">
        <Button variant="primary" className="px-[32px]" onClick={handleSubmit}>
          {t('page.login.submit')}
        </Button>
      </div>
    </div>
  );
}
