import React from 'react';
import { useTranslation } from 'react-i18next';
import WarningModal from './WarningModal';

interface NoDataSharedModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  requesterName: string;
  handleComplete: () => void;
}

export default function NoDataSharedModal({
  open,
  setOpen,
  requesterName,
  handleComplete,
}: NoDataSharedModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <WarningModal
      open={open}
      setOpen={setOpen}
      title={t('modal.noDataShared.title')}
      description={t('modal.noDataShared.description', { name: requesterName })}
      buttonText={t('modal.noDataShared.chooseData')}
      cancelButtonText={t('modal.noDataShared.continue')}
      onCancel={() => {
        handleComplete();
        setOpen(false);
      }}
      onSubmit={() => setOpen(false)}
    />
  );
}
