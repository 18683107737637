import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

/*
 * The logo for the navbar, small if phone big if pc.
 * @returns {JSX.Element} - The logo component.
 */
export default function NavBarLogo(): JSX.Element {
  return (
    <Link to="/">
      <div data-testid="nav-bar-logo " className="flex flex-shrink-0 cursor-pointer items-center">
        <img
          data-testid="nav-bar-logo-big"
          className="h-10 w-auto"
          src={`${process.env.PUBLIC_URL}/logo.svg`}
          alt="Vera Connect"
        />
      </div>
    </Link>
  );
}
