import React from 'react';
import CommonPageWrapper from '../../components/layouts/CommonPageWrapper';
import PrivacyText from '../../components/misc/PrivacyText';

export default function PrivacyPage(): JSX.Element {
  return (
    <CommonPageWrapper includeCommon={false}>
      <PrivacyText />
    </CommonPageWrapper>
  );
}
