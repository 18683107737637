import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useDelayedEffect from '../../../hooks/useDelayedEffect';

interface CommunicationNameInputProps {
  name: string;
  setName: (name: string) => void;
  mandatory?: boolean;
  validate?: boolean;
}

export default function CommunicationNameInput({
  name,
  setName,
  mandatory = false,
  validate = false,
}: CommunicationNameInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  useDelayedEffect(() => {
    if (!validate) return;
    if (!name) {
      setError(!mandatory ? '' : t('invalid.empty'));
      return;
    }
    setError('');
  }, [name, validate]);

  return (
      <div className="relative flex w-full flex-col">
        <label className="mb-1 pr-2 font-medium">
          {t('form.communicationName.communicationName')} {mandatory ? '*' : ''}
        </label>
        <input
          placeholder={t('form.communicationName.placeholder')}
          className={classNames(
            'h-10 w-full rounded-lg border px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
            {
              '!border-error': error,
            },
          )}
          name="email"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <p className="absolute right-1 top-0 text-sm text-error transition-all">{error}</p>
    </div>
  );
}
