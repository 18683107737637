import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { HiUserGroup } from 'react-icons/hi';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import Button from '../../components/misc/Button';
import { BusinessInvite } from '../../types/invites';
import ApiImage from '../../components/misc/ApiImage';

interface CommunityInvitePageProps {
  invite: BusinessInvite;
  onSubmit: () => void;
}

export default function NoRequestedDataCommunityInvitePage({
  invite: { requesterAlias: name, profilePicture: picture, memberCount, description },
  onSubmit,
}: CommunityInvitePageProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <ResponsePageWrapper>
      <ResponsePageWrapper.Header>
        <h1 className="text-2xl font-serif font-medium">
          {t('page.noRequestedDataCommunityInvitePage.header', { name })}
        </h1>
        <div className="flex">
          <img
            src="/images/misc/shield.svg"
            alt="Onboarding visual"
            className="object-cover w-5 h-5 mr-2 mt-[3px]"
          />
          <p className="font-light text-sm w-fit h-fit">{t('header.message', { name })}</p>
        </div>
        <div className="h-fit w-full flex flex-col p-3 pb-4 bg-secondary-200 rounded-[20px] mt-2 gap-4">
          <div
            className={classNames('flex w-full items-center gap-2', {
              'pl-5': !picture,
            })}>
            <ApiImage src={picture} alt={name} className="h-12 w-12 object-cover rounded-lg" />
            <div className="flex flex-col">
              {' '}
              <h2 className="font-medium text-lg font-serif">{name}</h2>
              {memberCount !== undefined && (
                <div className="flex items-center gap-1 text-sm">
                  <HiUserGroup className="w-5 h-5" />
                  {memberCount} {t('general.member', { count: memberCount || 2 })}
                </div>
              )}
            </div>
          </div>
          {description && <p className="-mt-1">{description}</p>}
        </div>
      </ResponsePageWrapper.Header>
      <div className="gap-4 h-full flex flex-col">
        <div className="flex flex-col">
          <h2 className="font-medium text-lg">
            {t('page.noRequestedDataCommunityInvitePage.title', { name })}
          </h2>
          <p className="text-sm">
            {t('page.noRequestedDataCommunityInvitePage.subtitle', { name })}
          </p>
        </div>
        <div className="flex flex-1 h-full justify-end items-end align-bottom min-h-10 mt-6">
          <Button variant="primary" className="px-[32px] " onClick={onSubmit}>
            {t('page.noRequestedDataCommunityInvitePage.submit', { name })}
          </Button>
        </div>
      </div>
    </ResponsePageWrapper>
  );
}
