import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../types/profile';
import Field from '../forms/userData/Field';
import ModalWrapper from '../layouts/ModalWrapper';
import Button from '../misc/Button';

interface PrimaryEmailModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClick: (email: string) => void;
  emails: ProfileField[];
}

export default function PrimaryEmailModal({
  open,
  setOpen,
  onClick,
  emails,
}: PrimaryEmailModalProps): JSX.Element {
  const [primary, setPrimary] = useState<ProfileField>();
  const { t } = useTranslation();
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="bg-secondary-50 min-w-[450px] w-fit pb-4 pt-5 mx-6 max-w-[600px] z-50 rounded-3xl py-6 px-10 h-a">
      <div className="flex flex-col gap-2">
        <h2 className="font-medium text-2xl">{t('modal.primaryEmail.title')}</h2>
        <p className="text-sm">{t('modal.primaryEmail.description')}</p>
      </div>

      <div className="flex flex-col gap-2 mt-2">
        {emails.map((email) => (
          <Field
            field={email}
            handleClick={(active, f) => (active ? setPrimary(undefined) : setPrimary(f))}
            active={email === primary}
          />
        ))}
      </div>
      <div className="justify-end flex mt-6">
        <Button className="underline" onClick={() => setOpen(false)}>
          {t('general.cancel')}
        </Button>
        <Button
          variant="primary"
          className=" px-[32px]"
          onClick={() => onClick(primary?.email!)}>
          {t('page.shared.acceptInvite')}
        </Button>
      </div>
    </ModalWrapper>
  );
}
