import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDeleteParams from '../../../hooks/useDeleteParams';
import { ProfileField } from '../../../types/profile';
import { ConnectionInvite, InviteType } from '../../../types/invites';
import { authenticationService, connectionService, invitationService } from '../../../services';
import { InvalidAccountResponse } from '../../../types/responses';
import UserDataForm from '../../../components/forms/userData/UserDataForm';
import ResponsePageWrapper from '../../../components/layouts/ResponsePageWrapper';
import InvalidAuthHeader from '../../../components/headers/InvalidAuthHeader';
import InvalidAuthBody from '../../../components/misc/InvalidAuthBody';
import DataRequestHeader from '../../../components/headers/DataRequestHeader';
import { SuccessPageState } from '../../../types/navigation';
import useAuth from '../../../hooks/useAuth';
import NoDataSharedModal from '../../../components/modals/NoDataSharedModal';
import Button from '../../../components/misc/Button';

export default function ConnectionInvitePage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const link = params.get('link');

  useDeleteParams('link');

  const inviteId = params.get('inviteId');

  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [invalidAccountInfo, setInvalidAccountInfo] = useState<InvalidAccountResponse>();
  const [invite, setInvite] = useState<ConnectionInvite>();
  const [noDataSharedModalOpen, setNoDataSharedModalOpen] = useState(false);

  useEffect(() => {
    if (!inviteId) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
      return;
    }

    (async () => {
      if (!auth?.email) {
        await authenticationService.loginMagic(link || '');
      }
    })()
      .then(() => invitationService.getConnectionInvite(inviteId))
      .then((inv): void => {
        setInvite(inv);
        setInvalidAccountInfo(undefined);
      })
      .catch((err: AxiosError<InvalidAccountResponse>): void => {
        if (err.response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          setInvalidAccountInfo(err.response.data);
        } else {
          toast.error(err.message);
          // toast.error(t('toast.error.general.inviteNotFound'));
          navigate('/');
        }
      });
  }, []);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setNoDataSharedModalOpen(true);
    else handleSubmit();
  };

  const handleSubmit = async (): Promise<void> => {
    await connectionService.respondToConnectionInvite(
      +inviteId!,
      true,
      selectedData.map((d) => d.id || ''),
    );
    const state: SuccessPageState = { translationKey: '' };

    navigate('/success', { state });
  };

  const handleDecline = async (): Promise<void> => {
    await connectionService.respondToConnectionInvite(
      +inviteId!,
      false,
      selectedData.map((d) => d.id || ''),
    );
    const state: SuccessPageState = { translationKey: '' };

    navigate('/success', { state });
  };

  const requesterName = invite?.inviterFirstName || '';

  if (invalidAccountInfo)
    return (
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InvalidAuthHeader type={InviteType.CONNECTION} />
        </ResponsePageWrapper.Header>
        <InvalidAuthBody info={invalidAccountInfo} invitationId={inviteId!} inviteType={InviteType.CONNECTION} />
      </ResponsePageWrapper>
    );

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <DataRequestHeader requesterName={requesterName || ''} />
        </ResponsePageWrapper.Header>
        <div className="flex h-full flex-1 flex-col gap-4">
          <div className="flex flex-col">
            <h2 className="text-lg font-medium">{t('page.dataRequest.title', { name: requesterName || '' })}</h2>
            <p className="text-sm">{t('page.dataRequest.description')}</p>
          </div>

          <UserDataForm
            selectedData={selectedData || []}
            setSelectedData={setSelectedData}
            onlyBirthDate
            nonRequestedFields={[]}
            hasSelectAll
          />
          <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
            <Button className="underline" onClick={handleDecline}>
              {t('general.decline')}
            </Button>
            <Button variant="primary" className="px-[32px]" onClick={handleComplete}>
              {t('page.shared.shareData')}
            </Button>
          </div>
        </div>
      </ResponsePageWrapper>
      <NoDataSharedModal
        open={noDataSharedModalOpen}
        setOpen={setNoDataSharedModalOpen}
        requesterName={invite?.inviterFirstName || ''}
        handleComplete={handleSubmit}
      />
    </>
  );
}
