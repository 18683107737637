import { MobileOS } from '../types/misc';

export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function detectMobileOS(): MobileOS {
  const { userAgent } = navigator;
  if (/Android/i.test(userAgent)) {
    return MobileOS.ANDROID;
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return MobileOS.IOS;
  }
  return MobileOS.UNKNOWN;
}
