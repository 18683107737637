import { CustomFieldResponses } from './invites';

export interface ValidProfileDataMap {
  [key: string]: boolean;
}

export interface SharedBusinessData {
  propertyIds: string[];
  customFieldResponses: CustomFieldResponses;
  dataForMembers: string[];
}

export enum MobileOS {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  UNKNOWN = 'UNKNOWN',
}

export interface Auth {
  alias: string;
  email: string;
}

export enum LinkType {
  MAGIC_LINK = 'MAGIC_LINK',
  CONNECTION_INVITE = 'CONNECTION_INVITE',
  COMMUNITY_INVITE = 'COMMUNITY_INVITE',
  PERSONAL_INVITE = 'PERSONAL_INVITE',
  BUSINESS_INVITE = 'BUSINESS_INVITE',
  MOMENTS_INVITE = 'MOMENTS_INVITE',
  PERSONAL_LINK = 'PERSONAL_LINK',
  LOCAL_CONTACT = 'LOCAL_CONTACT',
  EVENT_INVITE = 'EVENT_INVITE',
  EVENT_DATEPICKER_INVITE = 'EVENT_DATEPICKER_INVITE',
}

/**
 * Information about a redirect link
 * including the id of the link and the
 * type of the link
 */
export interface LinkInfo {
  type: LinkType;
  data: any;
  linkId: string;
}

export enum EmailPreferenceType {
  INTRODUCTIONEMAIL = 'INTRODUCTIONEMAIL',
  NEWCONNECTIONEMAIL = 'NEWCONNECTIONEMAIL',
  DATAREQUESTEMAIL = 'DATAREQUESTEMAIL',
  COMMUNITYEMAIL = 'COMMUNITYEMAIL',
}

export interface CommunityMember {
  /**
   * The user id of the member.
   */
  id: number;
  /**
   * The member's first name.
   */
  firstName: string;
  /**
   * The member's last name.
   */
  lastName: string;
  /**
   * The member's picture.
   */
  picture: string;
  /**
   * The member role in the community.
   */
  role: CommunityRole;
}

export enum CommunityRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export interface CommunityData {
  /**
   * The id of the community
   */
  id: number;

  /**
   * The businessId of the business connected to the community
   */
  businessId: number;

  /**
   * The name of the community
   */
  name: string;

  /**
   * The community picture
   */
  picture: string;

  /**
   * The number of members in the community
   */
  memberCount: number;

  /**
   * The community description
   */
  description: string;

  /**
   *
   */
  link: string;

  /**
   * The role of the user within the specific community.
   */
  role: CommunityRole;

  /**
   * The type of community.
   */
  type: CommunityType;
}

export enum CommunityType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  BUSINESS = 'BUSINESS',
}
