/* eslint-disable no-useless-return */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../types/profile';
import { PersonalInvite } from '../../types/invites';
import { connectionService, invitationService } from '../../services';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import useDeleteParams from '../../hooks/useDeleteParams';
import { SuccessPageState } from '../../types/navigation';
import { convertPersonalInviteToProfileFields } from '../../utils/formatUtils';
import AddFieldsHeader from '../../components/headers/AddFieldsHeader';
import ContactConfirmationModal from '../../components/modals/LocalContactConfirmationModal';
import useAuth from '../../hooks/useAuth';
import useProfileFields from '../../hooks/useProfileFields';
import { filterPresentFields } from '../../utils/filterUtils';
import { isEmptyField } from '../../utils/validationUtils';
import NoDataSharedModal from '../../components/modals/NoDataSharedModal';
import Button from '../../components/misc/Button';

export default function AuthenticatedPersonalInvitePage() {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const profileFields = useProfileFields();
  const auth = useAuth();
  useDeleteParams('link');

  const token = params.get('invitationId');

  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [invite, setInvite] = useState<PersonalInvite>();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [noDataSharedModalOpen, setNoDataSharedModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
      return;
    }

    invitationService
      .getPersonalInvite(token)
      .then((inv) => {
        setInvite(inv);
        const preSelectedValues = filterPresentFields(
          profileFields,
          convertPersonalInviteToProfileFields(inv).filter((f): boolean => !isEmptyField(f)),
        );

        setSelectedData(preSelectedValues);
        if (auth?.alias !== `${inv?.data.firstName!}+${inv?.data.lastName!}`) {
          setConfirmationModalOpen(true);
        }
      })
      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        navigate('/');
      });
  }, []);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setNoDataSharedModalOpen(true);
    else handleSubmit();
  };

  const handleSubmit = async (): Promise<void> => {
    await connectionService.createPersonalConnection(
      token!,
      selectedData.map((x) => +x.id!),
    );
    const state: SuccessPageState = {
      translationKey: 'page.common.success.message.invite',
      translationOptions: {
        requesterName: invite?.inviter.firstName!,
      },
      requesterId: `${invite?.inviter.userId!}`,
      requesterAlias: `${invite?.inviter.firstName}+${invite?.inviter.lastName}`,
    };
    navigate('/success', { state });
    toast.success(t('toast.success.connectionCreated'));
  };

  const requesterName = invite?.inviter.firstName || '';

  const fields = invite ? convertPersonalInviteToProfileFields(invite) : [];

  const addFields = (newFields: ProfileField[]): void => {
    setSelectedData([
      ...selectedData.filter((f) => !newFields.some((x) => x.dataType === f.dataType)),
      ...newFields,
    ]);
  };

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <AddFieldsHeader requesterName={requesterName} fields={fields} addFields={addFields} />
        </ResponsePageWrapper.Header>
        <div className="gap-4 flex flex-col flex-1 h-full">
          <div className="flex flex-col gap-2">
            <h2 className="font-medium text-lg">{t('page.shared.requestedData')}</h2>
            <p className="text-sm">{t('page.personalInvite.message', { requesterName })}</p>
          </div>

          <UserDataForm
            selectedData={selectedData || []}
            setSelectedData={setSelectedData}
            hasSelectAll
          />
        </div>

        <div className="flex flex-1 h-full justify-end items-end align-bottom min-h-10 mt-6">
          <Button variant="primary" className="px-[32px] " onClick={handleComplete}>
            {t('page.shared.shareData')}
          </Button>
        </div>
      </ResponsePageWrapper>
      <ContactConfirmationModal
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        info={{
          alias: `${invite?.data.firstName!}+${invite?.data.lastName!}`,
          receivedData: fields,
        }}
        inviterName={`${invite?.inviter.firstName!} ${invite?.inviter.lastName}`}
      />
      <NoDataSharedModal
        open={noDataSharedModalOpen}
        setOpen={setNoDataSharedModalOpen}
        requesterName={requesterName}
        handleComplete={handleSubmit}
      />
    </>
  );
}
