import React, { useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CommonPageWrapper from '../../components/layouts/CommonPageWrapper';
import useAuth from '../../hooks/useAuth';
import UserStatsDisplay from '../../components/misc/UserStatsDisplay';
import { UserStats } from '../../types/profile';
import { profileService } from '../../services';
import { redirectToApp } from '../../utils/appUtils';

export default function RedirectPage(): JSX.Element {
  const [params] = useSearchParams();
  const auth = useAuth();
  const [stats, setStats] = React.useState<UserStats>();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    const magicLinkId = params.get('link');
    const invitationId = params.get('invitationId');
    const inviteRoute = params.get('invite');
    const nav = params.get('nav');
    redirectToApp(magicLinkId, invitationId, nav, inviteRoute);
  }, []);

  useLayoutEffect(() => {
    if (!auth) return;
    profileService.getUserStatistics().then((data): void => {
      setStats(data);
    });
  }, []);

  return (
    <CommonPageWrapper>
      <CommonPageWrapper.Header>
        <div className="w-full relative items-center justify-center flex h-full bg-secondary rounded-[40px] p-5 py-10 gap-2.5">
          <img
            alt="Redirect"
            src={`${process.env.PUBLIC_URL}/images/figures/phone.svg`}
            className=" h-full w-[55%] mx-auto"
          />
        </div>
      </CommonPageWrapper.Header>
      <CommonPageWrapper.Title>Moment...</CommonPageWrapper.Title>
      <CommonPageWrapper.Message>{t('page.common.redirect.title')}</CommonPageWrapper.Message>
      {auth && <UserStatsDisplay stats={stats} />}
    </CommonPageWrapper>
  );
}

