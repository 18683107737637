import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { Frequency } from '../types/Events';

function getDatesBetween(startDate: Date, endDate: Date, interval: Frequency): Date[] {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));

    switch (interval) {
      case Frequency.DAILY:
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case Frequency.WEEKLY:
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case Frequency.BI_WEEKLY:
        currentDate.setDate(currentDate.getDate() + 14);
        break;
      case Frequency.MONTHLY:
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case Frequency.YEARLY:
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
      default:
        throw new Error('Invalid interval');
    }
  }
  return dates;
}

function calculateDayDifference(startDate: Date, endDate: Date): number {
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  const startDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const endDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  const diffDays = Math.round(Math.abs((endDay.getTime() - startDay.getTime()) / oneDay));
  return diffDays;
}

export function getFormattedStartEndDate(startDate: Date, endDate?: Date): string {
  const startDateString = format(startDate, 'dd-MM-yyyy');

  if (endDate) {
    const endDateString = format(endDate, 'dd-MM-yyyy');

    if (startDateString === endDateString) {
      return startDateString;
    }

    return `${startDateString} -> ${endDateString}`;
  }

  return startDateString;
}

const timeDifferenceString = (createdAt: Date | string, t: TFunction) => {
  const units = [
    { label: t('page.dashboard.time.yearText'), value: 365 * 24 * 60 * 60 * 1000 },
    { label: t('page.dashboard.time.monthText'), value: 30 * 24 * 60 * 60 * 1000 },
    { label: t('page.dashboard.time.weekText'), value: 7 * 24 * 60 * 60 * 1000 },
    { label: t('page.dashboard.time.dayText'), value: 24 * 60 * 60 * 1000 },
    { label: t('page.dashboard.time.hourText'), value: 60 * 60 * 1000 },
    { label: t('page.dashboard.time.minuteText'), value: 60 * 1000 },
  ];

  const previous = new Date(createdAt).valueOf();
  const current = new Date().valueOf();
  const elapsed = current - previous;

  const unit = units.find(({ value }) => elapsed >= value);

  if (unit) {
    const count = Math.floor(elapsed / unit.value);
    return `${count} ${unit.label}`;
  }

  return t('page.dashboard.time.justNow');
};

export default { getDatesBetween, calculateDayDifference, getFormattedStartEndDate, timeDifferenceString };
