import React from 'react';
import { ProfileField } from '../../../types/profile';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';

interface BusinessNameInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  mandatory?: boolean;
}

export default function SocialInput({ field, setField, mandatory = false }: BusinessNameInputProps): JSX.Element {
  if (!field.social) return <></>;
  const socialName = capitalizeFirstLetter(field.social.socialType);
  return (
    <div className="flex w-full flex-row items-center rounded-lg">
      <div className="flex w-full flex-col">
        <label htmlFor={field.id} className="focus:border-3 mb-1 pr-2 font-medium">
          {socialName} {mandatory ? '*' : ''}
        </label>
        <input
          id={field.id}
          placeholder={socialName}
          className="focus:border-3 h-10 w-full rounded-lg px-2 text-gray-600 focus:ring-0 focus:ring-transparent"
          name="businessName"
          type="text"
          value={field.social.link ?? ''}
          onChange={(e) => {
            setField({ ...field, social: { ...field.social!, link: e.target.value ?? '' } });
          }}
        />
      </div>
    </div>
  );
}
