import React from 'react';
import { HiUserGroup } from 'react-icons/hi';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommunityInvite } from '../../types/invites';
import ApiImage from '../misc/ApiImage';

interface CommunityInviteHeaderProps {
  invite: CommunityInvite;
  submessage?: string;
}
export default function CommunityInviteHeader({
  invite,
  submessage = '',
}: CommunityInviteHeaderProps): JSX.Element {
  const { t } = useTranslation();

  const { inviterFirstName, communityName, communityPicture, communityDescription, communitySize } =
    invite || {};

  return (
    <>
      <h1 className="text-2xl font-serif font-medium">
        {t('header.communityInvite.title', { inviterName: inviterFirstName, communityName })}
      </h1>
      <div className="flex">
        <img
          src="/images/misc/shield.svg"
          alt="Onboarding visual"
          className="object-cover w-5 h-5 mr-2 mt-[3px]"
        />
        <p className="font-light text-sm w-fit h-fit">
          {submessage || t('header.message', { name: inviterFirstName })}
        </p>
      </div>
      <div className="h-fit w-full flex flex-col p-3 pb-4 bg-secondary-200 rounded-[20px] mt-2 gap-4">
        <div
          className={classNames('flex w-full items-center gap-2', { 'pl-5': !communityPicture })}>
          {communityPicture && (
            <ApiImage
              src={communityPicture}
              alt={communityName}
              className="h-12 w-12 object-cover rounded-lg"
            />
          )}
          <div className="flex flex-col">
            {' '}
            <h2 className="font-medium text-lg font-serif">{communityName}</h2>
            <div className="flex items-center gap-1 text-sm">
              <HiUserGroup className="w-5 h-5" />
              {communitySize} {t('general.member', { count: communitySize || 2 })}
            </div>
          </div>
        </div>
        <p className="-mt-1">{communityDescription}</p>
      </div>
    </>
  );
}
