import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavBarLogo from '../../components/misc/NavBarLogo';
import { Language } from '../../types/requests';
import { setLanguage } from '../../redux/slices/applicationSlice';
import useLanguage from '../../hooks/useLanguage';
import Footer from '../../components/misc/Footer';

export default function AboutPage(): JSX.Element {
  const dispatch = useDispatch();
  const lang = useLanguage();
  const { t } = useTranslation();
  return (
    <>
        <div className="w-full h-16 sticky top-0 bg-secondary-200  rounded-b-[20px] flex  z-50 items-center px-4">
          <div className="flex w-full max-w-7xl justify-between items-center mx-auto ">
            <NavBarLogo />
            <img
              src={`${process.env.PUBLIC_URL}/images/flags/${
                lang === Language.NL ? Language.EN : Language.NL
              }.svg`}
              alt="Nederlands"
              onClick={() =>
                dispatch(setLanguage(lang === Language.NL ? Language.EN : Language.NL))
              }
              className="w-6 h-6 cursor-pointer"
            />
          </div>
        </div>
      <div className="w-full bg-secondary-50 flex flex-1 flex-col items-center min-h-[calc(100vh-128px)] py-10">
        <div className="w-full max-w-5xl">
          <h2 className="text-4xl font-medium mb-2">{t('page.about.about.title')}</h2>
          <p>{t('page.about.about.text')}</p>
          <h2 className="text-4xl font-medium mt-8 mb-2">{t('page.about.why.title')}</h2>
          <p>{t('page.about.why.text')}</p>
          <h2 className="text-2xl font-medium mt-4">{t('page.about.privacy.title')}</h2>
          <p>{t('page.about.privacy.text')}</p>

          <h2 className="text-2xl font-medium mt-4">{t('page.about.life.title')}</h2>
          <p>{t('page.about.life.text')}</p>
          <h2 className="text-4xl font-medium mt-8 mb-2">{t('page.about.contact.title')}</h2>
          <p>
            {t('page.about.contact.text')}
            <a
              href="mailto:?to=info@veraconnect.nl"
              target="_blank"
              rel="noreferrer"
              className="underline font-medium">
              info@veraconnect.nl
            </a>
            .
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
