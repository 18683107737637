import React from 'react';
import { useSelector } from 'react-redux';
import { Language, Locale } from '../types/requests';
import { RootState } from '../types/redux';

export default function useLanguage(): Language {
  return useSelector((state: RootState) => state.application.language);
}

export function useLocale(): string {
  const language = useLanguage();
  return language === Language.EN ? Locale.EN : Locale.NL;
}
