import React from 'react';
import { twMerge } from 'tailwind-merge';

interface PhoneLayoutProps {
  children: React.ReactNode;
  className?: string;
}
export default function PhoneLayout({ children, className }: PhoneLayoutProps): JSX.Element {
  return (
    <div>
      <div className="w-[300px] h-[600px] relative border-gray-800 border-[14px] rounded-[2.5rem]">
        <div className="h-[32px] w-[3px] bg-gray-800 z-[2] absolute -left-[17px] top-[72px] rounded-l-lg" />
        <div className="h-[46px] w-[3px] bg-gray-800 z-[2] absolute -left-[17px] top-[124px] rounded-l-lg" />
        <div className="h-[46px] w-[3px] bg-gray-800 z-[2] absolute -left-[17px] top-[178px] rounded-l-lg" />
        <div className="h-[64px] w-[3px] bg-gray-800 z-[2] absolute -right-[16px] top-[142px] rounded-r-lg" />
        <div className="h-[20px] w-[84px] bg-gray-800 z-[2] absolute mx-auto left-0 right-0 top-[10px] rounded-full" />
        <div className={twMerge('overflow-scroll w-full h-full rounded-[26px] no-scrollbar', className)}>
          {children}
        </div>
      </div>
    </div>
  );
}
