import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import NavBarLogo from '../misc/NavBarLogo';
import { authenticationService } from '../../services';
import useAuth from '../../hooks/useAuth';
import Footer from '../misc/Footer';
import { setLanguage } from '../../redux/slices/applicationSlice';
import { Language } from '../../types/requests';
import useLanguage from '../../hooks/useLanguage';

interface PageWrapperProps {
  children?: React.ReactNode;
  handleLogin?: () => void;
}

function ResponsePageWrapper({ children, handleLogin }: PageWrapperProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useLanguage();

  const header = React.Children.toArray(children).find((child) => React.isValidElement(child) && child.type === Header);

  const sidePanel = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === SidePanel,
  );

  const auth = useAuth();

  return (
    <>
      <div className="sticky top-0 z-50 flex h-16 w-full items-center rounded-b-[20px] bg-secondary-200">
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 desktop:px-0">
          <NavBarLogo />
          <div className="flex items-center gap-4">
            {handleLogin && !auth && (
              <p onClick={() => handleLogin?.()} className="cursor-pointer font-medium">
                {t('general.logIn')}
              </p>
            )}
            {auth && (
              <p onClick={authenticationService.logOut} className="cursor-pointer font-medium">
                {t('general.logOut')}
              </p>
            )}
            <img
              src={`${process.env.PUBLIC_URL}/images/flags/${lang === Language.NL ? Language.EN : Language.NL}.svg`}
              alt="Nederlands"
              onClick={() => dispatch(setLanguage(lang === Language.NL ? Language.EN : Language.NL))}
              className="h-6 w-6 cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="my-8 min-h-[calc(100vh-192px)] bg-secondary-50">
        <div className="mx-auto flex w-full max-w-7xl flex-1 flex-col justify-between gap-6 md:flex-row">
          {sidePanel ?? (
            <div className="sticky top-[96px] z-10 hidden h-full min-h-[400px] w-[65%] place-items-center items-center justify-center overflow-hidden rounded-3xl bg-primary-300 py-24 md:flex md:min-h-[600px]">
              <img src={`${process.env.PUBLIC_URL}/images/figures/phone.svg`} alt="Vera Phone" className="w-[40%]" />
            </div>
          )}

          <div className="rounded-3x flex h-fit min-h-[400px] w-full flex-col gap-4 bg-secondary-50 pt-4 md:min-h-[600px] md:w-[43%] md:min-w-[420px]">
            {header && <div className="w-full">{header}</div>}
            <div className="flex h-full w-full flex-col rounded-b-3xl px-4 md:px-0">
              {React.Children.toArray(children).filter(
                (child) => React.isValidElement(child) && child.type !== Header && child.type !== SidePanel,
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

interface HeaderProps {
  children: React.ReactNode;
}

function Header({ children }: HeaderProps): JSX.Element {
  return (
    <div className="flex h-fit w-full flex-col gap-2.5 rounded-3xl bg-secondary-50 px-4 md:rounded-b-none md:p-0">
      {children}
    </div>
  );
}

interface SidePanelProps {
  children: React.ReactNode;
  className?: string;
}

function SidePanel({ children, className }: SidePanelProps): JSX.Element {
  return (
    <div
      className={twMerge(
        'sticky top-[96px] z-10 hidden h-full min-h-[400px] w-[65%] place-items-center items-center justify-center overflow-hidden rounded-3xl bg-primary-300 py-24 md:flex md:min-h-[600px]',
        className,
      )}>
      {children}
    </div>
  );
}
ResponsePageWrapper.SidePanel = SidePanel;

ResponsePageWrapper.Header = Header;

export default ResponsePageWrapper;
