import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { FaClock } from 'react-icons/fa';
import { FaPencil } from 'react-icons/fa6';
import { EventParticipationAnswer } from '../../../types/Events';
import { useLocale } from '../../../hooks/useLanguage';
import { formatEventTime, formatFriendlyEventDate, getEventAttendanceAnswers } from '../../../utils/eventUtils';
import IconBadge from '../../icons/IconBadge';
import Icon from '../../icons/Icon';

type Props = {
  eventId: number;
  dateOption: any;
  dateOptionAnswer?: { answer?: EventParticipationAnswer; description?: string };
  onSave: (eventId: number, dateOptionId: number, answer: EventParticipationAnswer, description?: string) => void;
};

function EventSelectDateOption({ eventId, dateOption, dateOptionAnswer, onSave = () => {} }: Props) {
  const locale = useLocale();
  const { t } = useTranslation();

  const [showDescription, setShowDescription] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<EventParticipationAnswer>(
    dateOptionAnswer?.answer || EventParticipationAnswer.PENDING,
  );
  const [descriptionValue, setDescriptionValue] = useState<string>(dateOptionAnswer?.description || '');

  useEffect(() => {
    if (selectedAnswer === EventParticipationAnswer.PENDING) return;
    onSave(eventId, dateOption.id, selectedAnswer, descriptionValue.trim() || '');
    if (descriptionValue) setShowDescription(true);
  }, [selectedAnswer, descriptionValue]);

  const answers = getEventAttendanceAnswers(i18n);
  const formattedDate = formatFriendlyEventDate(
    new Date(dateOption.startTime),
    dateOption.endTime ? new Date(dateOption.endTime) : undefined,
    locale,
  );
  const formattedTime = formatEventTime(dateOption.startTime, dateOption.endTime);

  return (
    <div className="flex flex-col gap-2 border-b-2 border-secondary-50 pb-2">
      <div className="font-serif text-lg text-primary-900">{formattedDate}</div>
      <div className="flex justify-between gap-2">
        <div className="flex items-center gap-2">
          <IconBadge size="small" icon={FaClock} />
          <div className="ftext-sm text-primary-900">{formattedTime}</div>
        </div>
        <div className="flex flex-wrap justify-center gap-2">
          {answers.map(([answer, _, icon]) => (
            <button
              type="button"
              onClick={() => setSelectedAnswer(answer)}
              key={answer}
              className="flex flex-col items-center">
              <IconBadge
                icon={icon}
                className={`aspect-square w-auto rounded-xl outline outline-1 outline-primary-300 ${selectedAnswer === answer ? 'bg-primary' : 'bg-secondary-200'}`}
                iconClassName="text-primary-300"
              />
            </button>
          ))}
        </div>
      </div>
      {showDescription ? (
        <input
          type="text"
          value={descriptionValue}
          onChange={(e) => setDescriptionValue(e.target.value)}
          placeholder={`${i18n.t('page.events.invite.note')}...`}
          className="text-sm"
          autoFocus
        />
      ) : (
        <button
          type="button"
          className="flex shrink items-center gap-2 self-end font-bold text-primary hover:underline"
          onClick={() => setShowDescription(true)}>
          <Icon icon={FaPencil} className="text-primary" />
          {t('page.events.invite.addNote')}
        </button>
      )}
    </div>
  );
}

export default EventSelectDateOption;
