export async function redirectToApp(
  magicLinkId: string | null,
  invitationId: string | null,
  nav: string | null,
  inviteRoute: string | null,
): Promise<() => void> {
  let link = 'vera://login';
  if (nav && magicLinkId) link = `vera://magic?link=${magicLinkId}&nav=${nav}`;
  else if (invitationId && magicLinkId && inviteRoute)
    link = `vera://${inviteRoute}/invite?invitationId=${invitationId}&link=${magicLinkId}`;
  else if (invitationId && magicLinkId) link = `vera://invite?invitationId=${invitationId}&link=${magicLinkId}`;
  else if (magicLinkId) link = `vera://magic?link=${magicLinkId}`;

  const timeout = setTimeout(() => {
    window.location.replace(link);
  }, 1500);

  return () => clearTimeout(timeout);
}

export function redirectComWebsite(path = '') {
  window.location.assign(`https://www.veraconnect.com${path}`);
}
