import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGift } from 'react-icons/fa6';
import classNames from 'classnames';
import { Label, MomentType, ProfileDataType, ProfileField } from '../../types/profile';
import { connectionService } from '../../services';
import { getInitials, profileFieldToString } from '../../utils/stringUtils';
import { sortProfileFields } from '../../utils/sortUtils';
import { momentTypeIcons, profileDataTypeIcons, socialTypeIcons } from '../../utils/iconUtils';
import PhoneLayout from '../layouts/PhoneLayout';
import ApiImage from '../misc/ApiImage';

interface ConnectionOverviewScreenProps {
  requesterId?: string;
  requesterAlias?: string;
}

export default function ConnectionOverviewScreen({
  requesterId,
  requesterAlias,
}: ConnectionOverviewScreenProps): JSX.Element {
  if (!requesterId || !requesterAlias) return <></>;
  const { t } = useTranslation();
  const [profilePicture, setProfilePicture] = useState<string>();
  const [profileFields, setProfileFields] = useState<ProfileField[]>([]);

  useEffect(() => {
    connectionService
      .getProfilePictureOfUser(requesterId)
      .then((i) => {
        setProfilePicture(i);
      })
      .catch(() => {});
    connectionService.getSharedDataByUser(requesterId).then((d) => {
      setProfileFields(d);
    });
  }, []);

  const filter = (label: Label | string) => {
    return sortProfileFields(
      profileFields
        .filter((f) => f.label === label)
        .filter(
          (f) =>
            f.dataType !== ProfileDataType.BUSINESSNAME &&
            f.dataType !== ProfileDataType.SOCIAL &&
            f.dataType !== ProfileDataType.MOMENT &&
            f.dataType !== ProfileDataType.BIRTHDATE,
        ),
    );
  };

  const businessName = profileFields.find(
    (f) => f.dataType === ProfileDataType.BUSINESSNAME,
  )?.description;
  const generalFields = filter(Label.GENERAL).concat(filter(''));
  const privateFields = filter(Label.PRIVATE);
  const workFields = filter(Label.WORK);

  const renderLabel = (label: Label, fields: ProfileField[]): JSX.Element => {
    if (!fields.length) return <></>;
    return (
      <div className="px-5">
        <p className="text-primary text-sm mb-2">
          {t(`overview.connection.label.${label.toLocaleLowerCase()}`)}
        </p>
        {fields.map((f, i) =>
          renderField(
            profileDataTypeIcons[f.dataType],
            profileFieldToString(f),
            i !== fields.length - 1,
          ),
        )}
      </div>
    );
  };

  const renderMoments = (): JSX.Element => {
    const moments = profileFields.filter(
      (f) => f.dataType === ProfileDataType.MOMENT || f.dataType === ProfileDataType.BIRTHDATE,
    );
    if (!moments.length) return <></>;
    return (
      <div className="px-5 py-4">
        <p className="text-primary text-sm mb-2">{t('overview.connection.moments.title')}</p>
        {moments.map((f, i) => {
          const [year, month, day] = f.moment?.date.split('-') || f.birthDate?.split('-') || [];

          return (
            <div className="w-full flex gap-2">
              <div className="rounded-full bg-secondary h-fit w-fit flex items-center justify-center p-2 text-secondary-50">
                {f.dataType === ProfileDataType.BIRTHDATE
                  ? profileDataTypeIcons[f.dataType]
                  : momentTypeIcons[f.moment?.momentType!]}
              </div>
              <div
                className={classNames(
                  'text-xs items-center w-full border-secondary-200 py-2 pt-1 mb-[3px]',
                  { 'border-b': i !== moments.length - 1 },
                )}>
                <p>
                  {f.moment?.momentType === MomentType.WEDDING
                    ? t('overview.connection.moments.wedding')
                    : f.dataType === ProfileDataType.BIRTHDATE
                    ? t('overview.connection.moments.birthdate')
                    : t('overview.connection.moments.birthdateOf', { name: f.moment?.name })}
                </p>
                <p>{`${day}-${month}-${year}`}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSocials = (): JSX.Element => {
    const socials = profileFields.filter((f) => f.dataType === ProfileDataType.SOCIAL);

    if (!socials.length) return <></>;
    return (
      <div className="px-5 py-4">
        <p className="text-primary text-sm mb-2">Socials</p>
        {socials.map((f, i) =>
          renderField(
            socialTypeIcons[f.social?.socialType!],
            f.social?.link!,
            i !== socials.length - 1,
          ),
        )}
      </div>
    );
  };

  const renderField = (icon: JSX.Element, text: string, border: boolean): JSX.Element => (
    <div className="w-full flex gap-2">
      <div className="rounded bg-secondary h-fit w-fit flex items-center justify-center p-1 text-secondary-50">
        {icon}
      </div>
      <div
        className={classNames(
          'text-xs items-center w-full text-primary-900 border-secondary-200 py-2.5 mb-[3px] pt-1.5',
          { 'border-b': border },
        )}>
        {text}
      </div>
    </div>
  );

  return (
    <PhoneLayout className="bg-secondary-50">
      <div className="rounded-b-[40px] pt-10 px-5 py-5 gap-5 bg-secondary-200 w-full flex flex-col">
        <div className="flex gap-4 items-center">
          {profilePicture ? (
            <ApiImage
              src={profilePicture}
              alt={requesterAlias}
              className="rounded-[8px] w-[70px] object-cover h-[70px]"
            />
          ) : (
            <div className="rounded-[8px] w-[70px] h-[70px] flex bg-secondary text-secondary-50 justify-center items-center ">
              {getInitials(requesterAlias)}
            </div>
          )}
          <div className="flex flex-col ">
            <p className="text-primary-900 font-serif text-lg">
              {requesterAlias.replace('+', ' ')}
            </p>
            <p className="text-primary-900 text-xs">{businessName}</p>
          </div>
        </div>
        <div className="flex justify-between w-full mx-auto">
          <button
            type="button"
            className="bg-primary px-2 py-2 items-center justify-center text-xs text-secondary-50 rounded-[32px] flex gap-1">
            <span className="font-serif text-md">?</span>
            Vraag gegevens
          </button>
          <button
            type="button"
            className="bg-primary px-3 py-2 items-center justify-center  text-xs text-secondary-50 rounded-[32px] flex gap-1">
            <FaGift className="w-3 h-3" />
            Stuur kaartje
          </button>
        </div>
      </div>
      <div className="py-5 divide-y-4 divide-secondary-200">
        {renderLabel(Label.GENERAL, generalFields)}
        {renderLabel(Label.PRIVATE, privateFields)}
        {renderLabel(Label.WORK, workFields)}
        {renderMoments()}
        {renderSocials()}
      </div>
    </PhoneLayout>
  );
}
