import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApplicationState } from '../../types/redux';
import { ProfileField } from '../../types/profile';
import { Language } from '../../types/requests';

const initialState: ApplicationState = {
  isAuthenticated: false,
  language: Language.NL,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    logout(state): void {
      state.isAuthenticated = false;
    },

    login(state, action: PayloadAction<{ email: string; alias: string }>): void {
      state.isAuthenticated = true;
      state.email = action.payload.email;
      state.alias = action.payload.alias;
    },

    setProfileFields(state, action: PayloadAction<ProfileField[]>): void {
      state.profileFields = action.payload;
    },

    addProfileField(state, action: PayloadAction<ProfileField>): void {
      if (!state.profileFields) {
        state.profileFields = [];
      }
      state.profileFields.push(action.payload);
    },

    updateProfileField(state, action: PayloadAction<ProfileField>): void {
      if (!state.profileFields) {
        state.profileFields = [];
      }
      const index = state.profileFields.findIndex((field): boolean => field.id === action.payload.id);
      state.profileFields[index] = action.payload;
    },

    setLanguage(state, action: PayloadAction<Language>): void {
      state.language = action.payload;
    },
    setProfilePicture(state, action: PayloadAction<string | undefined>): void {
      state.profilePicture = action.payload;
    },
    setName(state, action: PayloadAction<string>): void {
      state.alias = action.payload;
    },
  },
});

export const {
  logout,
  login,
  setProfileFields,
  addProfileField,
  updateProfileField,
  setLanguage,
  setProfilePicture,
  setName,
} = applicationSlice.actions;
export default applicationSlice.reducer;
