import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ProfileField } from '../../../types/profile';
import useDelayedEffect from '../../../hooks/useDelayedEffect';
import { IsValidField } from '../../../utils/validationUtils';

interface BirthDateInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  validate?: boolean;
}

export default function BirthDateInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  validate = false,
}: BirthDateInputProps): JSX.Element {
  const [year, month, day] = field?.birthDate?.split('-') || ['', '', ''];
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  useDelayedEffect(() => {
    if (!validate) return;
    if (!year && !month && !day) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    if (!IsValidField(field, canBeEmpty)) {
      setError(t('invalid.invalid'));
      return;
    }
    setError('');
  }, [field.birthDate, validate]);

  useEffect(() => {
    if (!year && !month && !day && field.birthDate) {
      setField({ ...field, birthDate: '' });
    }
  }, [field]);

  return (
    <div className="relative flex w-fit flex-col">
      <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
        {t('form.birthDate.date')} {mandatory ? '*' : ''}
      </label>
      <div className="flex w-full flex-row items-center gap-2">
        <input
          name="bday-day"
          id={field.id}
          type="text"
          className={classNames(
            'mr-1 flex h-10 w-[45px] items-center rounded-lg border border-gray-400 px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
            {
              '!border-error': error,
            },
          )}
          placeholder="DD"
          data-testid={`suffix-input-${field.id}`}
          value={day || ''}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, '');
            setField({ ...field, birthDate: `${year || ''}-${month || ''}-${value}` });
          }}
          onBlur={(e) => {
            if (e.target.value.length === 1) {
              const value = `0${e.target.value}`;
              setField({ ...field, birthDate: `${year || ''}-${month || ''}-${value}` });
            }
          }}
        />
        -
        <input
          name="bday-month"
          id={field.id}
          type="text"
          className={classNames(
            'mr-1 flex h-10 w-[45px] items-center rounded-lg border border-gray-400 px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
            {
              '!border-error': error,
            },
          )}
          placeholder="MM"
          data-testid={`suffix-input-${field.id}`}
          value={month || ''}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, '');
            setField({ ...field, birthDate: `${year || ''}-${value}-${day || ''}` });
          }}
          onBlur={(e) => {
            if (e.target.value.length === 1) {
              const value = `0${e.target.value}`;
              setField({ ...field, birthDate: `${year || ''}-${value}-${day || ''}` });
            }
          }}
        />
        -
        <input
          name="bday-year"
          id={field.id}
          type="text"
          className={classNames(
            'mr-1 flex h-10 w-[65px] items-center rounded-lg border border-gray-400 px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
            {
              '!border-error': error,
            },
          )}
          placeholder="YYYY"
          data-testid={`suffix-input-${field.id}`}
          value={year || ''}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, '');
            setField({ ...field, birthDate: `${value}-${month || ''}-${day || ''}` });
          }}
          onBlur={(e) => {
            if (e.target.value.length === 1) {
              const value = `0${e.target.value}`;
              setField({ ...field, birthDate: `${value}-${month || ''}-${day || ''}` });
            }
          }}
        />
      </div>
      <p className="absolute right-1 top-0 text-sm text-error transition-all">{error}</p>
    </div>
  );
}
