import { UserAccountCreationEvent } from '../analytics/models';
import { login, logout } from '../redux/slices/applicationSlice';
import { store } from '../redux/store';
import { InviteType } from '../types/invites';
import { LoginRequest, MagicGenerationRequest, RegisterRequest } from '../types/requests';
import { LoginResponse, RegisterResponse } from '../types/responses';
import httpService, { setRefreshToken, setToken, wipeToken } from './httpService';

export function loginMagic(magicLinkId: string): Promise<void> {
  const body: LoginRequest = {
    magicLinkId,
  };
  return httpService
    .post<LoginResponse>('/auth/loginMagic', body)
    .then(({ data: { accessToken, refreshToken, userAlias, userEmail } }): void => {
      setToken(accessToken);
      setRefreshToken(refreshToken);

      store.dispatch(
        login({
          alias: userAlias,
          email: userEmail,
        }),
      );
    });
}

export function generateMagicLink(
  email: string,
  invitationId?: string,
  communityInvitationId?: string,
  inviteType?: InviteType,
  personalLinkId?: string,
  eventId?: string,
): Promise<void> {
  const body: MagicGenerationRequest = {
    email,
    invitationId,
    communityInvitationId,
    inviteType,
    personalLinkId,
    eventId,
  };
  return httpService.post('/auth/magicLink', body);
}

export function logOut(): void {
  wipeToken();
  store.dispatch(logout());
}

export function register(alias: string, email: string, event: UserAccountCreationEvent): Promise<() => void> {
  const body: RegisterRequest = { alias, email, event };

  return httpService
    .post<RegisterResponse>('/auth/register', body)
    .then(({ data: { accessToken, refreshToken } }): (() => void) => {
      setToken(accessToken);
      setRefreshToken(refreshToken);

      return () =>
        setTimeout(() => {
          store.dispatch(
            login({
              alias,
              email,
            }),
          );
        }, 1);
    });
}
