import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaPencilAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CommonPageWrapper from '../../components/layouts/CommonPageWrapper';
import { invitationService, profileService } from '../../services';
import { RootState } from '../../types/redux';
import Avatar from '../../components/misc/Avatar';
import { ProfileDataType, UserStats } from '../../types/profile';
import Button from '../../components/misc/Button';
import UserStatsDisplay from '../../components/misc/UserStatsDisplay';
import ProfileDataModal from '../../components/modals/ProfileDataModal';
import {
  CommunityInviteInfo,
  CommunityRequestWithDate,
  ConnectionInviteWithDate,
  DataRequestWithDate,
  EventInviteInfo,
  InboxBody,
  IntroductionWithDate,
} from '../../types/inbox';
import DownloadButton from '../../components/misc/DownloadButton';
import Field from '../../components/forms/userData/Field';
import dateUtils from '../../utils/dateUtils';
import { sortProfileFields } from '../../utils/sortUtils';

enum InboxType {
  INTRODUCTION = 'INTRODUCTION',
  DATA_REQUEST = 'DATA_REQUEST',
  COMMUNITY_INVITE = 'COMMUNITY_INVITE',
  EVENT_INVITE = 'EVENT_INVITE',
  CONNECTION_INVITE = 'CONNECTION_INVITE',
  COMMUNITY_REQUEST = 'COMMUNITY_REQUEST',
}

type InboxEntry =
  | IntroductionWithDate
  | DataRequestWithDate
  | CommunityInviteInfo
  | EventInviteInfo
  | ConnectionInviteWithDate
  | CommunityRequestWithDate;

export default function DashboardPage() {
  const { t } = useTranslation();
  const { profileFields, profilePicture, alias } = useSelector((state: RootState) => state.application);

  const [stats, setStats] = useState<UserStats>();
  const [modalOpen, setModalOpen] = useState(false);
  const [inbox, setInbox] = useState<InboxBody>();

  useEffect(() => {
    profileService.getUserData();
    profileService.getProfilePicture();
    profileService.getUserStatistics().then(setStats);
    invitationService.getRequestsAndInvites().then(setInbox);
  }, []);

  const getTitle = () => {
    const date = new Date();
    const hour = date.getHours();
    if (hour < 12) return t('page.dashboard.morning');
    if (hour < 18) return t('page.dashboard.afternoon');
    return t('page.dashboard.evening');
  };

  const getPanel = (type: InboxType, entry: InboxEntry) => {
    if (!inbox) return <></>;
    switch (type) {
      case InboxType.INTRODUCTION:
        entry = entry as IntroductionWithDate;
        return <IntroductionPanel id={entry.id} introductions={inbox.introductions} />;
      case InboxType.DATA_REQUEST:
        entry = entry as DataRequestWithDate;
        return <DataRequestPanel request={entry} />;
      case InboxType.COMMUNITY_INVITE:
        entry = entry as CommunityInviteInfo;
        return <CommunityInvitePanel invite={entry} />;
      case InboxType.EVENT_INVITE:
        entry = entry as EventInviteInfo;
        return <EventInvitepanel invite={entry} />;
      case InboxType.CONNECTION_INVITE:
        entry = entry as ConnectionInviteWithDate;
        return <ConnectionInvitePanel invite={entry} />;
      case InboxType.COMMUNITY_REQUEST:
        entry = entry as CommunityRequestWithDate;
        return <CommunityRequestPanel request={entry} />;
      default:
        return <></>;
    }
  };

  const entries = [
    ...(inbox?.introductions ?? []).map((i) => ({ ...i, inboxType: InboxType.INTRODUCTION })),
    ...(inbox?.dataRequests ?? []).map((r) => ({ ...r, inboxType: InboxType.DATA_REQUEST })),
    ...(inbox?.communityInvites ?? []).map((i) => ({ ...i, inboxType: InboxType.COMMUNITY_INVITE })),
    ...(inbox?.eventInvites ?? []).map((i) => ({ ...i, inboxType: InboxType.EVENT_INVITE })),
    ...(inbox?.connectionInvites ?? []).map((i) => ({ ...i, inboxType: InboxType.CONNECTION_INVITE })),
    ...(inbox?.communityRequests ?? []).map((r) => ({ ...r, inboxType: InboxType.COMMUNITY_REQUEST })),
  ].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  const fields = sortProfileFields(profileFields?.filter((f) => f.dataType !== ProfileDataType.MOMENT) ?? []);

  return (
    <>
      <CommonPageWrapper includeCommon={false} containerClassName="w-full md:w-full pt-0">
        <div className="flex h-full w-full flex-col justify-between gap-8 lg:flex-row">
          <div className="flex w-full flex-col gap-6">
            <h1 className="font-serif text-5xl">
              {getTitle()}, {alias?.split('+')[0]}
            </h1>
            <UserStatsDisplay stats={stats} />
            <div className="flex flex-col gap-4">{entries.map((entry) => getPanel(entry.inboxType, entry))}</div>
            <div className="mt-6 flex flex-col gap-4 border-t border-secondary-200 pt-6">
              <p className="text-base font-normal md:w-[70%]">{t('page.common.message')}</p>
              <DownloadButton />
            </div>
          </div>
          <div className="flex h-fit w-full flex-col gap-2 rounded-[20px] bg-secondary-200 px-5 py-4 lg:w-[60%]">
            <div className="flex w-full items-center justify-between gap-4 px-2">
              <div className='flex gap-2 items-center'>
                <Avatar className="h-[60px] w-[60px] text-xl" alias={alias ?? ''} src={profilePicture} />
                <h1 className="font-serif text-3xl">{alias?.replace('+', ' ')}</h1>
              </div>
              <Button className="flex-shrink-0 rounded-full p-3" variant="primary" onClick={() => setModalOpen(true)}>
                <FaPencilAlt className="h-5 w-5" />
              </Button>
            </div>
            {fields.length && (
              <div className="flex flex-col">
                {fields.map((f) => (
                  <Field key={f.id} field={f} />
                ))}
              </div>
            )}
          </div>
        </div>
      </CommonPageWrapper>
      <ProfileDataModal open={modalOpen} setOpen={setModalOpen} />
    </>
  );
}

interface IntroductionPanelProps {
  id: string;
  introductions: IntroductionWithDate[];
}
function IntroductionPanel({ id, introductions }: IntroductionPanelProps) {
  const introduction = introductions.find((i) => i.id === id);
  if (!introduction) return <></>;
  const { t } = useTranslation();
  return (
    <Link to={`/introductions?id=${id}`} state={{ introductions }}>
      <div className="flex justify-between gap-2 rounded-[8px] border border-secondary-200 px-6 py-5">
        <div className="flex items-center gap-2">
          <Avatar
            alias={introduction.introducerAlias}
            src={introduction.introducerPicture}
            className="h-12 w-12 rounded-[6px] text-lg"
          />
          <div className="flex flex-col">
            <h1 className="font-serif text-lg">{t('page.dashboard.introduction.title')}</h1>
            <p className="text-md">
              {t('page.dashboard.introduction.subtitle', {
                introducer: introduction.introducerAlias.replace('+', ' '),
                introduced: introduction.introducedAlias.replace('+', ' '),
              })}
            </p>
          </div>
        </div>
        <p className="flex-shrink-0">{dateUtils.timeDifferenceString(introduction.createdAt, t)}</p>
      </div>
    </Link>
  );
}

interface DataRequestPanelProps {
  request: DataRequestWithDate;
}

function DataRequestPanel({ request }: DataRequestPanelProps) {
  const { t } = useTranslation();
  return (
    <Link to="/data-request" state={{ request }}>
      <div className="flex justify-between rounded-[8px] border border-secondary-200 px-6 py-5">
        <div className="flex flex-col">
          <h1 className="font-serif text-lg">{t('page.dashboard.dataRequest.title')}</h1>
          <p className="text-md">
            {t('page.dashboard.dataRequest.subtitle', { name: request.requesterAlias.replace('+', ' ') })}
          </p>
        </div>
        <p className="flex-shrink-0">{dateUtils.timeDifferenceString(request.createdAt, t)}</p>
      </div>
    </Link>
  );
}

interface CommunityInvitePanelProps {
  invite: CommunityInviteInfo;
}

function CommunityInvitePanel({ invite }: CommunityInvitePanelProps) {
  const { t } = useTranslation();
  return (
    <Link to={`/moments/invite?invitationId=${invite.businessInviteLinkId}&type=COMMUNITY`} state={{ invite }}>
      <div className="flex justify-between rounded-[8px] border border-secondary-200 px-6 py-5">
        <div className="flex flex-col">
          <h1 className="font-serif text-lg">{t('page.dashboard.communityInvite.title')}</h1>
          <p className="text-md">
            {t('page.dashboard.communityInvite.subtitle', {
              name: invite.inviterAlias.replace('+', ' '),
              communityName: invite.communityName,
            })}
          </p>
        </div>
        <p className="flex-shrink-0">{dateUtils.timeDifferenceString(invite.createdAt, t)}</p>
      </div>
    </Link>
  );
}

interface EventInvitePanelProps {
  invite: EventInviteInfo;
}

function EventInvitepanel({ invite }: EventInvitePanelProps) {
  const { t } = useTranslation();
  return (
    <Link to={`/event?id=${invite.eventId}`} state={{ invite }}>
      <div className="flex justify-between rounded-[8px] border border-secondary-200 px-6 py-5">
        <div className="flex flex-col">
          <h1 className="font-serif text-lg">{t('page.dashboard.eventInvite.title')}</h1>
          <p className="text-md">
            {t('page.dashboard.eventInvite.subtitle', { name: invite.organizer, event: invite.eventTitle })}
          </p>
        </div>
        <p className="flex-shrink-0">{dateUtils.timeDifferenceString(invite.createdAt, t)}</p>
      </div>
    </Link>
  );
}

interface ConnectionInvitePanelProps {
  invite: ConnectionInviteWithDate;
}

function ConnectionInvitePanel({ invite }: ConnectionInvitePanelProps) {
  const { t } = useTranslation();
  return (
    <Link to={`/connectionInvite?inviteId=${invite.inviteId}`} state={{ invite }}>
      <div className="flex justify-between rounded-[8px] border border-secondary-200 px-6 py-5">
        <div className="flex flex-col">
          <h1 className="font-serif text-lg">{t('page.dashboard.connectionInvite.title')}</h1>
          <p className="text-md">{t('page.dashboard.connectionInvite.subtitle', { name: invite.inviterFirstName })}</p>
        </div>
        <p className="flex-shrink-0">{dateUtils.timeDifferenceString(invite.createdAt, t)}</p>
      </div>
    </Link>
  );
}

interface CommunityRequestPanelProps {
  request: CommunityRequestWithDate;
}

function CommunityRequestPanel({ request }: CommunityRequestPanelProps) {
  const { t } = useTranslation();
  return (
    <Link to="/group/connect" state={{ request }}>
      <div className="flex justify-between rounded-[8px] border border-secondary-200 px-6 py-5">
        <div className="flex flex-col">
          <h1 className="font-serif text-lg">{t('page.dashboard.communityRequest.title')}</h1>
          <p className="text-md">
            {t('page.dashboard.communityRequest.subtitle', {
              name: request.alias.replace('+', ' '),
              communityName: request.communityName,
            })}
          </p>
        </div>
        <p className="flex-shrink-0">{dateUtils.timeDifferenceString(request.createdAt, t)}</p>
      </div>
    </Link>
  );
}
