import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DownloadButton from './DownloadButton';

export default function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="text-medium flex h-fit min-h-[64px] w-full items-center rounded-t-[20px] bg-primary-900 px-6 py-6 text-secondary-50 desktop:px-0">
      <div className="flex w-full max-w-7xl flex-col justify-between md:mx-auto md:flex-row md:items-center">
        <div className="flex flex-col gap-4">
          <DownloadButton className="flex-col-reverse lg:flex-col-reverse" />
          <p className="font-serif text-xl text-secondary-50">{`Copyright ® ${new Date().getFullYear()} Vera Connect`}</p>
        </div>
        <div className="mt-4 flex w-fit flex-col gap-1 text-right md:mt-0">
          <a href="https://www.veraconnect.com" target="_blank" className="text-xl underline" rel="noreferrer">
            Vera Connect
          </a>
          <a
            href="https://www.veraconnect.com/privacy-statement"
            target="_blank"
            className="text-xl underline"
            rel="noreferrer">
            Privacy
          </a>
          <a href="https://www.veraconnect.com/about" target="_blank" className="text-xl underline" rel="noreferrer">
            {t('footer.about')}
          </a>
          <a href="https://www.veraconnect.com/contact" target="_blank" className="text-xl underline" rel="noreferrer">
            Contact
          </a>
        </div>
      </div>
    </div>
  );
}
