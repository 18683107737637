import { useSelector } from 'react-redux';
import { ApplicationState, RootState } from '../types/redux';
import { Auth } from '../types/misc';

export default function useAuth(): Auth | undefined {
  const { isAuthenticated, alias, email } = useSelector(
    (state: RootState): ApplicationState => state.application,
  );
  if (!isAuthenticated) return undefined;
  return { alias: alias!, email: email! };
}
