import { FaInfoCircle } from 'react-icons/fa';
import React from 'react';
import { IconType } from 'react-icons';
import Icon from '../icons/Icon';

interface Props {
    label?: string;
    children?: React.ReactNode;
    icon?: IconType;
}

function InfoBox({ label, children, icon = FaInfoCircle }: Props) {
    return (
        <div className="flex flex-row items-center gap-2 rounded-2xl bg-secondary p-3">
            <Icon icon={icon} className="text-white" />
            {label && <p className="text-md">{label}</p>}
            {children}
        </div>
    );
}

export default InfoBox;
