import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ProfileField } from '../../types/profile';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import InviteHeader from '../../components/headers/InviteHeader';
import useDeleteParams from '../../hooks/useDeleteParams';
import { PersonalLinkData } from '../../types/invites';
import { connectionService, linkService, personalLinkService } from '../../services';
import NoDataSharedModal from '../../components/modals/NoDataSharedModal';
import Button from '../../components/misc/Button';
import { SuccessPageState } from '../../types/navigation';
import useProfileFields from '../../hooks/useProfileFields';
import { getUserId } from '../../services/httpService';

export default function AuthenticatedPersonalLinkPage(): JSX.Element {
  const { t } = useTranslation();
  const [params, _] = useSearchParams();
  const navigate = useNavigate();
  const profileFields = useProfileFields();

  useDeleteParams('link');

  const linkId = params.get('linkId');

  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [linkData, setLinkData] = useState<PersonalLinkData>();
  const [noDataSharedModalOpen, setNoDataSharedModalOpen] = useState<boolean>(false);

  useEffect(() => {
    linkService
      .getLinkInfo(linkId as string)
      .then((linkInfo) => setLinkData(linkInfo.data as PersonalLinkData))
      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        navigate('/');
      });
  }, []);

  useEffect(() => {
    if (!linkData || profileFields.length === 0) return;

    const loggedInUserId = getUserId();
    if (+linkData.userId === +loggedInUserId) {
      //this is the loggedin user own invite
      toast.warning('Je kan niet reageren op je eigen link');
      navigate('/');
    }

    connectionService
      .getSharedDataWithUser(linkData.userId.toString())
      .then((data) => {
        setSelectedData(data);
      })
      .catch((): void => {});
  }, [linkData, profileFields]);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setNoDataSharedModalOpen(true);
    else handleSubmit();
  };

  const handleSubmit = () => {
    const sharedProps = selectedData.map((d) => +(d.id || ''));
    personalLinkService.usePersonalLink(linkId as string, sharedProps).then(() => {
      const state: SuccessPageState = {
        translationKey: 'page.common.success.message.personalLink',
        translationOptions: {
          name: linkData?.firstName!,
        },
      };
      navigate('/success', { state });
    });
  };

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InviteHeader requesterName={linkData?.firstName || ''} />
        </ResponsePageWrapper.Header>
        <div className="gap-4 flex flex-col flex-1 h-full">
          <div className="flex flex-col">
            <h2 className="font-medium text-lg">{t('page.shared.requestedData')}</h2>
            <p className="text-sm">
              {t('page.invite.message', {
                name: linkData?.firstName || '',
                dataRequest: '',
              })}{' '}
              {t('page.invite.selectData')}
            </p>
          </div>

          <UserDataForm
            selectedData={selectedData || []}
            setSelectedData={setSelectedData}
            hasSelectAll
          />
          <div className="flex flex-1 h-full justify-end items-end align-bottom min-h-10 mt-6">
            <Button variant="primary" className="px-[32px]" onClick={handleComplete}>
              {t('page.shared.shareData')}
            </Button>
          </div>
        </div>
      </ResponsePageWrapper>
      <NoDataSharedModal
        open={noDataSharedModalOpen}
        setOpen={setNoDataSharedModalOpen}
        requesterName={linkData?.firstName || ''}
        handleComplete={handleSubmit}
      />
    </>
  );
}
