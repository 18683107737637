import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileDataType } from '../../types/profile';
import { getInitials } from '../../utils/stringUtils';
import { profileDataTypeIcons } from '../../utils/iconUtils';
import { sortProfileDataTypes } from '../../utils/sortUtils';

interface BusinessCardHeaderProps {
  requesterAlias: string;
  providedTypes: ProfileDataType[];
  profilePicture: string;
}

export default function BusinessCardHeader({
  requesterAlias,
  providedTypes,
  profilePicture,
}: BusinessCardHeaderProps): JSX.Element {
  const [firstName, lastName] = requesterAlias.split('+');
  const { t } = useTranslation();

  const getSecretStringForDataType = (dataType: ProfileDataType): string => {
    switch (dataType) {
      case ProfileDataType.PHONENUMBER:
        return '+31* *** *** **';
      case ProfileDataType.EMAIL:
        return '*********@*****.***';
      case ProfileDataType.ADDRESS:
        return '*************** **\n****** *****\n*********';
      case ProfileDataType.BIRTHDATE:
      case ProfileDataType.MOMENT:
        return '** - ** - ****';
      case ProfileDataType.BUSINESSNAME:
        return '******** **';
      case ProfileDataType.SOCIAL:
        return 'www.************.com';
      default:
        return '**********';
    }
  };

  return (
    <>
      <h1 className="text-2xl font-serif font-medium">
        {t('header.businessCard.title', { name: firstName })}
      </h1>
      <div className="flex">
        <img
          src="/images/misc/shield.svg"
          alt="Onboarding visual"
          className="object-cover w-5 h-5 mr-2 mt-[3px]"
        />
        <p className="font-light text-sm w-fit h-fit">{t('header.message', { name: firstName })}</p>
      </div>
      <div className="h-fit w-full flex flex-col p-3 pb-4 bg-secondary-200 rounded-xl mt-2 gap-4">
        <div className="flex w-full items-center gap-2">
          {profilePicture ? (
            <img
              src={profilePicture}
              alt={firstName}
              className="h-12 w-12 object-cover rounded-lg"
            />
          ) : (
            <div className="h-12 w-12 bg-secondary flex items-center justify-center text-secondary-50 rounded-lg text-xl">
              {getInitials(requesterAlias)}
            </div>
          )}
          <h2 className="font-medium text-lg">{`${firstName} ${lastName}`}</h2>
        </div>
        <div className="px-6 gap-2 flex flex-col">
          {sortProfileDataTypes(providedTypes).map((type) => (
            <div key={type} className="flex gap-2">
              <div className="bg-secondary text-secondary-50 rounded-[6px] h-fit flex items-center justify-center p-1">
                {profileDataTypeIcons[type as keyof typeof profileDataTypeIcons]}
              </div>
              <div className="flex flex-col gap-1 my-auto">
                {getSecretStringForDataType(type)
                  .split('\n')
                  .map((line) => (
                    <p className="text-sm w-fit text-secondary-50 bg-secondary-50 rounded-md">{line}</p>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
