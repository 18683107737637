import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../types/profile';
import { connectionService, invitationService } from '../../services';
import { Invite } from '../../types/invites';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import useProfileFields from '../../hooks/useProfileFields';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import InviteHeader from '../../components/headers/InviteHeader';
import { joinWithAnd, profileDataTypeToString } from '../../utils/stringUtils';
import useDeleteParams from '../../hooks/useDeleteParams';
import { SuccessPageState } from '../../types/navigation';
import { getUserId } from '../../services/httpService';
import BusinessCardHeader from '../../components/headers/BusinessCardHeader';
import NoDataSharedModal from '../../components/modals/NoDataSharedModal';
import Button from '../../components/misc/Button';

export default function AuthenticatedInvitePage(): JSX.Element {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const profileFields = useProfileFields();
  useDeleteParams('link');

  const invitationId = params.get('invitationId');

  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [invite, setInvite] = useState<Invite>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!invitationId) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
      return;
    }

    invitationService
      .getInvite(invitationId)
      .then((inv) => setInvite(inv))
      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        navigate('/');
      });
  }, []);

  useEffect(() => {
    if (!invite || profileFields.length === 0) return;

    const loggedInUserId = getUserId();
    if (+invite.providerId === +loggedInUserId) {
      //this is the loggedin user own invite
      toast.warning('Je kan niet reageren op je eigen link');
      navigate('/');
    }

    connectionService
      .getSharedDataWithUser(invite.providerId)
      .then((data) => {
        setSelectedData(data);
      })
      .catch((): void => {});
  }, [invite, profileFields]);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setModalOpen(true);
    else handleSubmit();
  };

  const handleSubmit = async (): Promise<void> => {
    await connectionService.createConnection(
      invitationId!,
      selectedData!.map((x) => x.id!),
    );

    const state: SuccessPageState = {
      translationKey: 'page.common.success.message.invite',
      translationOptions: {
        requesterName: requesterName || '',
      },
      requesterId: invite?.providerId,
      requesterAlias: invite?.provider.profile.alias,
    };
    navigate('/success', { state });
    toast.success('Verbinding aangemaakt');
  };

  const requesterName = invite?.provider.profile.alias.split('+')[0];

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          {invite?.dataRequest.length === 0 ? (
            <BusinessCardHeader
              requesterAlias={invite?.provider.profile.alias}
              providedTypes={invite?.dataProvidedTypes!}
              profilePicture={invite?.profilePicture!}
            />
          ) : (
            <InviteHeader requesterName={requesterName || ''} />
          )}
        </ResponsePageWrapper.Header>
        <div className="flex h-full flex-1 flex-col gap-4">
          <div className="flex flex-col">
            <h2 className="text-lg font-medium">{t('page.shared.requestedData')}</h2>
            <p className="text-sm">
              {invite?.dataRequest.length === 0
                ? t('page.invite.businessCardMessage', { name: requesterName })
                : t('page.invite.message', {
                    name: requesterName,
                    dataRequest: joinWithAnd(invite?.dataRequest.map(profileDataTypeToString) || []),
                  })}{' '}
              {t('page.invite.selectData')}
            </p>
          </div>

          <UserDataForm selectedData={selectedData || []} setSelectedData={setSelectedData} hasSelectAll />
          <div className="flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
            <Button type="button" variant="primary" onClick={handleComplete} className="px-[32px]">
              {t('page.shared.shareData')}
            </Button>
          </div>
        </div>
      </ResponsePageWrapper>
      <NoDataSharedModal
        open={modalOpen}
        setOpen={setModalOpen}
        requesterName={requesterName || ''}
        handleComplete={handleSubmit}
      />
    </>
  );
}
