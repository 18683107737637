import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import PrivacyAndConditionsModal from '../modals/PrivacyAndConditionsModal';

interface TermsAndConditionsProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  name?: string;
  className?: string;
}

/*
 * A component that asks to accept the terms and conditions
 *
 * @param {() => void} setState - The function to execute on click.
 * @returns {JSX.Element} - The component.
 */
export default function TermsAndConditions({
  checked,
  setChecked,
  name,
  className = '',
}: TermsAndConditionsProps): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  return (
    <div className={twMerge('flex items-center', className)}>
      <input
        id="link-checkbox"
        data-testid="tac-checkbox"
        type="checkbox"
        value=""
        className="form-checkbox h-5 w-5 cursor-pointer rounded-full border-primary bg-secondary-50 text-primary-300 accent-primary-900 focus:ring-0 focus:ring-transparent"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
      <p
        className="text-b-900 dark:text-black-300 ml-2 cursor-pointer text-sm font-medium"
        onClick={() => setChecked(!checked)}>
        {name ? t('terms.agreeAndShare', { name }) : t('terms.agree')}
        <span
          data-testid="tac-link"
          className="text-primary hover:cursor-pointer hover:text-primary-300 hover:underline"
          onClick={(e) => {
            e.stopPropagation(); // Prevent the parent onClick from being called
            setOpen(true);
          }}>
          {t('terms.terms')}
        </span>
      </p>
      <PrivacyAndConditionsModal open={open} setOpen={setOpen} />
    </div>
  );
}
