import React from 'react';
import PhoneLayout from '../layouts/PhoneLayout';

export default function ContactsOverviewScreen(): JSX.Element {
  return (
    <PhoneLayout className=" bg-gray-800 overflow-hidden">
      <img
        src={`${process.env.PUBLIC_URL}/images/misc/ContactInfo.png`}
        className='h-full w-full object-right'
        alt="ContactInfo"
      />
    </PhoneLayout>
  );
}
