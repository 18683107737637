import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../layouts/ModalWrapper';
import Button from '../misc/Button';

interface WarningModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description: string;
  cancelButtonText?: string;
  buttonText: string;
  onCancel?: () => void;
  onSubmit: () => void;
}
export default function WarningModal({
  open,
  setOpen,
  title,
  description,
  cancelButtonText,
  buttonText,
  onCancel,
  onSubmit,
}: WarningModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="bg-secondary-50 flex flex-col gap-4 min-w-[450px] p-10 mx-6 max-w-[600px] z-50  py-6 rounded-3xl h-a">
      <div className="flex flex-col">
        <h3 className="text-base font-semibold leading-6 ">{title}</h3>
        <p className="text-sm ">{description}</p>
      </div>
      <div className="flex justify-end">
        <Button className="underline" onClick={onCancel ?? (() => setOpen(false))}>
          {cancelButtonText || t('general.cancel')}
        </Button>
        <Button variant="primary" className="px-[32px]" onClick={onSubmit}>
          {buttonText}
        </Button>
      </div>
    </ModalWrapper>
  );
}
