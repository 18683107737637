import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

import { API_URL } from '../constants';
import i18n from '../locales/i18n';
import { logout } from '../redux/slices/applicationSlice';
import { store } from '../redux/store';
import { trimObject } from '../utils/formatUtils';

const httpService: AxiosInstance = axios.create({
  baseURL: API_URL,
});

const attachToken = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = localStorage.getItem('token');
  request.headers.Authorization = token ? `Bearer ${token}` : '';
  return request;
};

export const attachVersion = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  // eslint-disable-next-line global-require
  request.headers['App-Version'] = `web-${require('../../package.json').version}`;
  return request;
};

const trimBody = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (request.data) {
    request.data = trimObject(request.data);
  }
  return request;
};

export function getUserId(): number {
  const token = localStorage.getItem('token') ?? '';
  const data = jwtDecode<JwtData>(token);
  return data.payload.userId;
}

httpService.interceptors.request.use(attachToken);
httpService.interceptors.request.use(attachVersion);
httpService.interceptors.request.use(trimBody);

const handleUnAuthorizedError = async (error: any): Promise<any> => {
  if (error.response.data?.code === 30001) {
    wipeToken();
    store.dispatch(logout());
  }
  if (error.response?.data?.code === 10001) {
    const originalRequest = error.config;

    const refresh = localStorage.getItem('refreshToken');
    if (!refresh) return Promise.reject(error);
    try {
      const response = await axios.post(`${API_URL}/auth/refreshtoken`, { refreshToken: refresh });
      const { accessToken, refreshToken } = response.data;

      setToken(accessToken);
      setRefreshToken(refreshToken);
      if (!originalRequest) return await Promise.reject(error);
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      return await httpService(originalRequest);
    } catch (refreshError) {
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

httpService.interceptors.response.use(null, handleUnAuthorizedError);

const handleError = (error: any): Promise<void> => {
  if (!error.response.data.print) return Promise.reject(error);
  const errorMessage = error.response.data.error;

  toast.error(errorMap[errorMessage as keyof typeof errorMap] || errorMessage);

  return Promise.reject(error);
};

httpService.interceptors.response.use(null, handleError);

export const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};
export const setRefreshToken = (token: string): void => {
  localStorage.setItem('refreshToken', token);
};

export const wipeToken = (): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};

export default httpService;

const errorMap = {
  'Requested a new magic link too soon.': i18n.t('toast.server.linkTooSoon'),
  'There is no user with such primary email!': i18n.t('toast.server.inexistantUser'),
  'User already exists!': i18n.t('toast.server.userAlreadyExists'),
};
